import {
  createAction,
  createReducer,
  createSelector,
  PayloadAction,
} from '@reduxjs/toolkit';
import {call, put, select, takeLatest} from 'redux-saga/effects';
import {RootState} from 'StoreTypes';
import {
  FlightsState,
  FlightsFilterProps,
  SearchIntentProps,
  NumberOfPassengersProps,
  AgentsFilterProps,
  AirlinesFilterProps,
} from './types';
import Api from '../../services/api';
import {
  extractIataCodes,
  groupFlights,
  sortByPrice,
} from '../../core/helpers/SortFlights';

const flightsFilterState = {
  stops: {
    direct: true,
    oneStop: true,
    twoStops: true,
  },
  departureTime: {
    min: 0,
    max: 1439,
  },
  returnTime: {
    min: 0,
    max: 1439,
  },
  price: {
    min: 0,
    max: 0,
  },
  flightDuration: 0,
};
/** Initial state */
export const INITIAL_STATE: FlightsState = {
  isOneWayTrip: false,
  isLoadingRequestFlights: false,
  searchIntent: null,
  webSocketChannelsList: null,
  sksFlights: [],
  webSocketConnectedList: [],
  totalExpectedResponses: 0,
  currentResponsesCount: 0,
  flightsFilter: flightsFilterState,
  airlinesFilter: [],
  airportsFilter: [],
  numberOfPassengers: {
    adults: 1,
    children: 0,
    babies: 0,
  },
  isSearchFinished: false,
  agentsFilter: [],
  progressResultPercentage: 0,
};

/** Action creators */
export const resquestFlights = createAction<{
  searchIntent: SearchIntentProps;
}>('RESQUEST_FLIGHTS');
export const successResquestFlights = createAction('SUCCESS_RESQUEST_FLIGHTS');
export const errorResquestFlights = createAction('ERROR_RESQUEST_FLIGHTS');
export const setSearchIntent = createAction<{
  searchIntent: SearchIntentProps;
}>('SET_SEARCH_INTENT');

export const setSksFlights = createAction<{sksFlights: any}>('SET_SKS_FLIGHTS');

export const setwebSocketConnectedList = createAction<{
  webSocketConnectedList: WebSocket[];
}>('SET_WEBSOCKET_CONNECTED_LIST');

export const setCurrentResponsesCount = createAction(
  'SET_CURRENT_RESPONSES_COUNT',
);

export const setFlightsFilter = createAction<{
  flightsFilter: FlightsFilterProps;
}>('SET_FLIGHTS_FILTER');

export const successFlightsFilter = createAction('SUCCESS_FLIGHTS_FILTER');
export const errorFlightsFilter = createAction('ERROR_FLIGHTS_FILTER');
export const setAirportsFilter = createAction<{airportsFilter: any}>(
  'SET_AIRPORTS_FILTER',
);

export const setIsOnewayTrip = createAction<{isOnewayTrip: boolean}>(
  'SET_IS_ONEWAY_TRIP',
);

export const setNumberOfPassengers = createAction<{
  numberOfPassengers: NumberOfPassengersProps;
}>('SET_NUMBER_OF_PASSENGERS');

export const setIsSearchFinished = createAction('SET_IS_SEARCH_FINISHED');

export const setAirlinesFilter = createAction<{
  airlinesFilter: AirlinesFilterProps[];
}>('SET_AIRLINES_FILTER');

export const setAgentsFilter = createAction<{
  agentsFilter: AgentsFilterProps[];
}>('SET_AGENTS_FILTER');

export const setProgressResult = createAction<{
  progressResultPercentage: number;
}>('SET_RESULT_PROGRESS');

const calculateTotalDataSize = (channels: any[]): number => {
  return channels.reduce((totalSize, channel) => {
    const cacheData = channel.cacheData;
    return (
      totalSize +
      cacheData.skyscanner.data.length +
      cacheData.chalinga.data.length +
      cacheData.zupper.data.length +
      cacheData.kiwi.data.length +
      cacheData.capo.data.length +
      cacheData.vaiDePromo.data.length
    );
  }, 0);
};

/** Reducer */
export default createReducer(INITIAL_STATE, {
  [resquestFlights.type]: (state, action) => ({
    ...state,
    isLoadingRequestFlights: true,
  }),
  [successResquestFlights.type]: (state, action) => ({
    ...state,

    webSocketChannelsList: action.payload.webSocketChannelsList,
    totalExpectedResponses: action.payload.totalExpectedResponses,
  }),
  [errorResquestFlights.type]: state => ({
    ...state,
    isLoadingRequestFlights: false,
  }),
  [setSearchIntent.type]: (state, action) => ({
    ...state,
    searchIntent: {...action.payload.searchIntent},
  }),
  [setSksFlights.type]: (state, action) => ({
    ...state,
    isLoadingRequestFlights: false,
    sksFlights: groupFlights(action.payload.sksFlights.sort(sortByPrice)),
    airportsFilter: extractIataCodes(action.payload.sksFlights),
  }),
  [setCurrentResponsesCount.type]: (state, action) => ({
    ...state,
    currentResponsesCount:
      state.currentResponsesCount < state.totalExpectedResponses
        ? state.currentResponsesCount + 1
        : state.currentResponsesCount,
  }),
  [setFlightsFilter.type]: (state, action) => ({
    ...state,
    flightsFilter: {...action.payload.flightsFilter},
  }),
  [setAirportsFilter.type]: (state, action) => ({
    ...state,
    airportsFilter: action.payload.airportsFilter,
  }),

  [setIsOnewayTrip.type]: (state, action) => ({
    ...state,
    isOneWayTrip: action.payload.isOnewayTrip,
  }),

  [setNumberOfPassengers.type]: (state, action) => ({
    ...state,
    numberOfPassengers: {...action.payload.numberOfPassengers},
  }),
  [setIsSearchFinished.type]: (state, action) => ({
    ...state,
    isSearchFinished: true,
  }),

  [setAgentsFilter.type]: (state, action) => ({
    ...state,
    agentsFilter: action.payload.agentsFilter,
  }),

  [setAirlinesFilter.type]: (state, action) => ({
    ...state,
    airlinesFilter: action.payload.airlinesFilter,
  }),
  [setProgressResult.type]: (state, action) => ({
    ...state,
    progressResultPercentage: action.payload.progressResultPercentage,
  }),
});

/** Sagas */

// eslint-disable-next-line require-yield
export function* resquestFlightsSaga(
  action: PayloadAction<{searchIntent: FlightsFilterProps}>,
): Generator {
  try {
    console.log('resquestFlightsSaga');

    const {searchIntent} = action.payload;

    const response: any = yield call(Api.createSearchIntent, {
      ...searchIntent,
    });

    const state: any = yield select();
    const currentWebSocketChannelsList = state.webSocketChannelsList ?? [];
    const newWebSocketChannelsList = response.data.message.wsChannels;
    const statusChannels = newWebSocketChannelsList[0].status;
    const newTotalDataSize = calculateTotalDataSize(newWebSocketChannelsList);
    const currentTotalDataSize = calculateTotalDataSize(
      currentWebSocketChannelsList,
    );

    if (statusChannels === 'finished') {
      yield put({
        type: setIsSearchFinished.type,
      });
    } else if  (newTotalDataSize > currentTotalDataSize) {
      yield put({
        type: successResquestFlights.type,
        payload: {
          webSocketChannelsList: newWebSocketChannelsList,
          totalExpectedResponses: response.data.message.totalExpectedRequests,
        },
      });
    }
  } catch (error) {
    console.error(error);
    yield put({
      type: errorResquestFlights.type,
      payload: {},
    });
  }
}

export const flightsSagas = [
  takeLatest(resquestFlights.type, resquestFlightsSaga),
];

/** Selectors */
const rootSelector = (state: RootState): FlightsState => state.flights;

export const getAirlinesFilter = createSelector(
  [rootSelector],
  flights => flights.airlinesFilter,
);

export const getSearchIntent = createSelector(
  [rootSelector],
  flights => flights.searchIntent,
);

export const getWebSocketChannelsList = createSelector(
  [rootSelector],
  flights => flights.webSocketChannelsList,
);

export const getSksFlights = createSelector(
  [rootSelector],
  flights => flights.sksFlights,
);

export const getTotalExpectedResponses = createSelector(
  [rootSelector],
  flights => flights.totalExpectedResponses,
);

export const getCurrentResponsesCount = createSelector(
  [rootSelector],
  flights => flights.currentResponsesCount,
);

export const getIsLoadingRequestFlights = createSelector(
  [rootSelector],
  flights => flights.isLoadingRequestFlights,
);

export const getFlightsFilter = createSelector(
  [rootSelector],
  flights => flights.flightsFilter,
);

export const getAirportsFilter = createSelector(
  [rootSelector],
  flights => flights.airportsFilter,
);

export const getIsOneWayTrip = createSelector(
  [rootSelector],
  flights => flights.isOneWayTrip,
);

export const getNumberOfPassengers = createSelector(
  [rootSelector],
  flights => flights.numberOfPassengers,
);

export const getIsSearchFinished = createSelector(
  [rootSelector],
  flights => flights.isSearchFinished,
);

export const getAgentsFilter = createSelector(
  [rootSelector],
  flights => flights.agentsFilter,
);

export const getProgressResultPercentage = createSelector(
  [rootSelector],
  flights => flights.progressResultPercentage,
);
