/* eslint-disable quotes */
import {error} from 'console';
import {
  API_CROSS_SELL,
  API_OFFERS_TIPS,
  API_SEARCH_FLIGHTS,
} from '../../config/apiConfig';

const Api = {
  /**
   * Send a POST request to login user
   */
  login: (emial: string, password: string): Promise<object> =>
    API_SEARCH_FLIGHTS.post(`/login?email=${emial}&password=${password}`),

  /**
   * Send a GET request to logout user
   */
  logout: (): Promise<object> => API_SEARCH_FLIGHTS.get('/logout'),

  /**
   * Send a GET request a profile data
   */
  getProfile: (sessionId?: string) =>
    API_SEARCH_FLIGHTS.get(
      // 'http://localhost:8080/api/users/me?sessionId=ba620a397f3bbfaea54df67e039b2d9c',
      'https://voopter-node-api.herokuapp.com/api/users/me?sessionId=03f42a8f96b857e7f0bdaf0c3b09aed1',
    )
      .then(res => {
        console.log(res);
      })
      .catch(error => {
        console.error(error);
      }),

  /**
   * Send a GET request adspace (inline) service from cross sell API
   */

  getAdspace: ({queryParams}: {queryParams: string}): Promise<object> =>
    // API_CROSS_SELL.get(`/adspace/all/${queryParams}`),
    API_CROSS_SELL.get(`/adspace/all/${queryParams}`),

  /**
   * Send a GET request adspace (sideSearch) service from cross sell API
   */

  getSideSearch: ({queryParams}: {queryParams: string}): Promise<object> =>
    API_CROSS_SELL.get(`/sidesearch/all/${queryParams}`),

  createSearchIntent: (searchIntent: object): Promise<object> =>
    API_SEARCH_FLIGHTS.post(`/createSearchIntent`, {
      searchIntent,
    }),

  getOffrersTips: ({
    postType,
    perPage,
    paged,
    orderBy,
  }: {
    postType: string;
    perPage: string;
    paged: string;
    orderBy: string;
  }): Promise<object> =>
    API_OFFERS_TIPS.get(
      `/wp_api/v1/posts/?post_type=${postType}&per_page=${perPage}&paged=${paged}&order_by=${orderBy}&nocache=${Math.random()}`,
    ),
};

export default Api;
