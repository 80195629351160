/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import React, {useRef, useState} from 'react';
import './App.css';
import Api from './services/api';
import {useEffect} from 'react';
import ReactPaginate from 'react-paginate';
import JSONdata from './results3.json';
import './paginate-styles.css';
import {respProps} from './types';
import Header from './Components/Navigator/Header';
import SearchFlights from './Components/Search/SearchFlights';
import BodyContent from './Components/Containers/BodyContent';
import FlightCard from './Components/FlightComponents/FlightCard';
import {useDispatch, useSelector} from 'react-redux';
import flights, {
  getCurrentResponsesCount,
  getWebSocketChannelsList,
  resquestFlights,
  setCurrentResponsesCount,
  setIsOnewayTrip,
  setIsSearchFinished,
  setNumberOfPassengers,
  setProgressResult,
  setSearchIntent,
  setSksFlights,
} from './store/flights';
import {isSearchUrl, parseSearchParams} from './core/helpers/String';
import {SearchIntentProps, WebSocketChannelsProps} from './store/flights/types';
import {sortByPrice} from './core/helpers/SortFlights';
import {requestBlogPosts} from './store/offersTips';
import GoogleTagManager from './config/GoogleTagManager/GoogleTagManager.js';
import {getServer} from './endpointManager';
import {resquestInline, resquestSideSearch} from './store/crossSell';
import {parseCrossSellData} from './core/helpers/CorssSell';

const dataFligths: any = JSONdata;
const maxSearchTryNumber: number = 10;
const pollingFrequency: number = 3000;

function App() {
  const [totalSearchItent, setTotalSearchItent] = useState(0);
  const [callSearchItent, setCallSearchItent] = useState(0);
  const webSocketChannels = useSelector(getWebSocketChannelsList);
  const [allChannelsFinished, setAllChannelsFinished] = useState(false);
  const dispatch = useDispatch();
  const webSocketChannelsRef = useRef(webSocketChannels);

  function adjustMonth(dateString: string): string {
    const [year, month, day] = dateString.split('-').map(Number);
    const newMonth = month - 1;
    const adjustedDate = `${year}-${newMonth.toString().padStart(2, '0')}-${day
      .toString()
      .padStart(2, '0')}`;
    return adjustedDate;
  }

  function adjustDates(dates: string[]): string[] {
    return dates.map(date => adjustMonth(date));
  }

  useEffect(() => {
    webSocketChannelsRef.current = webSocketChannels;
  }, [webSocketChannels]);

  useEffect(() => {
    dispatch(
      requestBlogPosts({
        postType: 'post',
        perPage: '8',
        paged: '1',
        orderBy: 'date',
      }),
    );

    const searchUrl: SearchIntentProps | any = parseSearchParams(
      window.location.href,
    );

    setTotalSearchItent(
      searchUrl?.returnDate?.length
        ? searchUrl.departureDate?.length * searchUrl?.returnDate?.length
        : searchUrl.departureDate?.length ?? 0,
    );

    dispatch(
      setNumberOfPassengers({
        numberOfPassengers: {
          adults: searchUrl.quantityOfAdults ?? 1,
          children: searchUrl.quantityOfChildrens ?? 0,
          babies: searchUrl.quantityOfBabies ?? 0,
        },
      }),
    );

    dispatch(
      setIsOnewayTrip({
        isOnewayTrip: searchUrl?.returnDate?.length
          ? false
          : searchUrl?.departureDate?.length
          ? true
          : false,
      }),
    );

    if (isSearchUrl(window.location.href)) {
      const crossSellParams = parseCrossSellData(
        searchUrl.originAirport,
        searchUrl.destinationAirport,
        searchUrl.departureDate[0],
        searchUrl?.returnDate[0] ?? null,
        searchUrl.quantityOfAdults,
        searchUrl.quantityOfChildrens,
        searchUrl.quantityOfBabies,
      );

      dispatch(
        resquestInline({
          queryParams: crossSellParams,
        }),
      );

      const searchIntentObject = {
        searchIntent: {
          originAirport: searchUrl.originAirport,
          destinationAirport: searchUrl.destinationAirport,
          departureDate: searchUrl.departureDate,
          returnDate: searchUrl.returnDate,
          quantityOfAdults: searchUrl.quantityOfAdults,
          quantityOfChildrens: searchUrl.quantityOfChildrens,
          quantityOfBabies: searchUrl.quantityOfBabies,
        },
      };

      const searchIntentObject2 = {
        searchIntent: {
          originAirport: searchUrl.originAirport,
          destinationAirport: searchUrl.destinationAirport,
          departureDate: adjustDates(searchUrl.departureDate),
          returnDate: adjustDates(searchUrl.returnDate),
          quantityOfAdults: searchUrl.quantityOfAdults,
          quantityOfChildrens: searchUrl.quantityOfChildrens,
          quantityOfBabies: searchUrl.quantityOfBabies,
        },
      };

      dispatch(setSearchIntent(searchIntentObject2));
      dispatch(resquestFlights(searchIntentObject));

      let count = 0;
      const intervalId = setInterval(() => {
        if (count < maxSearchTryNumber) {
          dispatch(resquestFlights(searchIntentObject));
          count++;
          setCallSearchItent(count);

          if (
            webSocketChannelsRef.current?.every(
              (item: any) => item.status === 'finished',
            )
          ) {
            clearInterval(intervalId);
          }
        } else {
          dispatch(setProgressResult({progressResultPercentage: 100}));
          dispatch(setIsSearchFinished());
          clearInterval(intervalId);
        }
      }, pollingFrequency);
    }
  }, []);

  useEffect(() => {
    // Function to check if all channels are finished
    const checkAllChannelsFinished = () => {
      if (webSocketChannels?.every(item => item.status === 'finished')) {
        setAllChannelsFinished(true);
      } else {
        setAllChannelsFinished(false);
      }
    };

    checkAllChannelsFinished(); //
    const intervalId = setInterval(checkAllChannelsFinished, 1000);

    getPercentageResults();

    return () => clearInterval(intervalId);
  }, [webSocketChannels]);

  useEffect(() => {
    webSocketChannels?.every(
      (item: WebSocketChannelsProps) => item.status === 'finished',
    ) && dispatch(setIsSearchFinished());

    getPercentageResults();
    webSocketChannels?.map((item: WebSocketChannelsProps) => {
      try {
        dispatch(setSksFlights({sksFlights: mergedData(item.cacheData)}));
        // dispatch(setCurrentResponsesCount());
        // console.log('cacheData = ', item.cacheData);
      } catch (error) {
        console.log('connectWS error: ', error);
      }
    });
  }, [webSocketChannels]);

  const mergedData = (cacheData: any) => {
    let cacheDataResponse: any[] = [];

    webSocketChannels?.map((item: any) => {
      cacheDataResponse = [
        ...cacheDataResponse,
        ...item.cacheData.skyscanner.data,
        ...item.cacheData.kiwi.data,
        ...item.cacheData.zupper.data,
        ...item.cacheData.chalinga.data,
        ...item.cacheData.capo.data,
        ...item.cacheData.vaiDePromo.data,
        // ...cacheData.amadeus.data,
        // ...cacheData.amadeusMpCommunity.data,
      ];
    });
    return cacheDataResponse;
  };

  const getPercentageResults = () => {
    let finishedCount = 0;

    webSocketChannels?.forEach(item => {
      if (item.cacheData && typeof item.cacheData === 'object') {
        Object.values(item.cacheData).forEach(dataSource => {
          if (dataSource && dataSource.status === 'finished') {
            finishedCount++;
          }
        });
      }
    });

    const percentage = webSocketChannels?.length
      ? (finishedCount * 100) / (webSocketChannels?.length * 6)
      : 0;

    percentage >= 100 && dispatch(setIsSearchFinished());
    dispatch(setProgressResult({progressResultPercentage: percentage}));
  };

  return (
    <div className="App antialiased">
      <Header />

      <div className="div-main">
        <div className="div-main-gradient" />
        <SearchFlights />
        <BodyContent callSearchItent={callSearchItent} />
      </div>
    </div>
  );
}

export default App;