import {
  createAction,
  createReducer,
  createSelector,
  PayloadAction,
} from '@reduxjs/toolkit';
import {call, put, takeLatest} from 'redux-saga/effects';
import {RootState} from 'StoreTypes';
import {CrossSellState} from './types';
import Api from '../../services/api';

/** Initial state */
export const INITIAL_STATE: CrossSellState = {
  isLoadingRequestInline: false,
  isLoadingRequestSideSearch: false,
  widgetLinks: [],
  inlineData: null,
  sideSearchData: null,
};

/** Action creators */
export const resquestInline = createAction<{queryParams: string}>(
  'RESQUEST_INLINE',
);
export const successResquestInline = createAction('SUCCESS_RESQUEST_INLINE');
export const errorResquestInline = createAction('ERROR_RESQUEST_INLINE');

export const resquestSideSearch = createAction<{queryParams: string}>(
  'RESQUEST_SIDE_SEARCH',
);
export const successResquestSideSearch = createAction(
  'SUCCESS_RESQUEST_SIDE_SEARCH',
);
export const errorResquestSideSearch = createAction(
  'ERROR_RESQUEST_SIDE_SEARCH',
);

/** Reducer */
export default createReducer(INITIAL_STATE, {
  [resquestInline.type]: (state, action) => ({
    ...state,
    isLoadingRequestInline: true,
    inlineData: null,
  }),
  [successResquestInline.type]: (state, action) => ({
    ...state,
    isLoadingRequestInline: false,
    inlineData: action.payload.inlineData,
  }),
  [errorResquestInline.type]: state => ({
    ...state,
    isLoadingRequestInline: false,
  }),
  [resquestSideSearch.type]: (state, action) => ({
    ...state,
    isLoadingRequestSideSearch: true,
    sideSearchData: null,
  }),
  [successResquestSideSearch.type]: (state, action) => ({
    ...state,
    isLoadingRequestSideSearch: false,
    sideSearchData: action.payload.sideSearchData,
  }),
  [errorResquestSideSearch.type]: state => ({
    ...state,
    isLoadingRequestSideSearch: false,
  }),
});

/** Sagas */

export function* resquestInlineSaga(
  action: PayloadAction<{queryParams: string}>,
): Generator {
  try {
    const {queryParams} = action.payload;

    const response: any = yield call(Api.getAdspace, {
      queryParams,
    });

    yield put({
      type: successResquestInline.type,
      payload: {inlineData: response?.data[0]},
    });
  } catch (error) {
    yield put({
      type: errorResquestInline.type,
      payload: {},
    });
  }
}

export function* resquestSideSearchSaga(
  action: PayloadAction<{queryParams: string}>,
): Generator {
  const {queryParams} = action.payload;

  const response: any = yield call(Api.getSideSearch, {
    queryParams,
  });

  try {
    yield put({
      type: successResquestSideSearch.type,
      payload: {sideSearchData: response?.data},
    });
  } catch (error) {
    yield put({
      type: errorResquestInline.type,
      payload: {},
    });
  }
}

export const crossSellSagas = [
  takeLatest(resquestInline.type, resquestInlineSaga),
  // takeLatest(resquestSideSearch.type, resquestSideSearchSaga),
];

/** Selectors */
const rootSelector = (state: RootState): CrossSellState => state.crossSell;

export const getInlineData = createSelector(
  [rootSelector],
  crossSell => crossSell.inlineData,
);

export const getSideSearchData = createSelector(
  [rootSelector],
  crossSell => crossSell.sideSearchData,
);
