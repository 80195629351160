/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-restricted-globals */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, {useEffect, useRef, useState} from 'react';
import './BodyContent.css';
import InlineBanner from '../../CrossSell/InlineBanner';
import {useDispatch, useSelector} from 'react-redux';
import {getInlineData, getSideSearchData} from '../../../store/crossSell';
import FlightCard from '../../FlightComponents/FlightCard';
import JSONdata from '../../../results.json';
import JSONdataOneWay from '../../../sampleOneWay.json';
import FlightsFilter from '../../DataEntry/FlightsFilter';
import Animation from '../../Animation';
import {
  getAgentsFilter,
  getAirlinesFilter,
  getAirportsFilter,
  getCurrentResponsesCount,
  getFlightsFilter,
  getIsLoadingRequestFlights,
  getIsOneWayTrip,
  getIsSearchFinished,
  getNumberOfPassengers,
  getProgressResultPercentage,
  getSearchIntent,
  getSksFlights,
  getTotalExpectedResponses,
  setAgentsFilter,
  setAirlinesFilter,
  setAirportsFilter,
  setFlightsFilter,
  setIsSearchFinished,
} from '../../../store/flights';
import ReactPaginate from 'react-paginate';
import ProgressBar from '../../StatusDisplay/ProgressBar';

import * as FlighsSearchAnimation from '../../../assets/animations/flightsSearch.json';
import * as noFilterdFlights from '../../../assets/animations/noFilterdFlights.json';
import * as OkAnimation from '../../../assets/animations/ok-results.json';
import OfferTipCard from '../../DisplayData/OfferTipCard';
import {getOffersTipsPosts} from '../../../store/offersTips';
import {offerTipPostProps} from '../../../store/offersTips/types';
import {title} from 'process';
import {
  convertArrayStringToObjects,
  convertStrigToMinutes,
  minuteToClock,
  parseAmout,
} from '../../../core/helpers/DateTime';
import cardImageDefault from '../../../assets/OffersTipsCard/card-image-default.jpeg';
import {sortByPrice} from '../../../core/helpers/SortFlights';
import {FiArrowUpRight, FiArrowDownLeft, FiFilter, FiX} from 'react-icons/fi';
import mobileAppsImage from '../../../assets/images/mobile-app.png';
import howToUseImage from '../../../assets/images/how-to-use.jpeg';
import {AiFillApple, AiFillAndroid} from 'react-icons/ai';
import Button from '../../Buttons/Button';
import AdBanner from '../../../core/helpers/Ads';
import SideSearchBanner from '../../CrossSell/SideSearchBanner';
import FooterModal from '../FooterModal';
import {
  flightDataProps,
  flightDetailsProps,
} from '../../FlightComponents/FlightCard/types';
import Icon from '../../Icon';
import {CalendarDateProps} from '../../DataEntry/CalendarPicker/types';
import CheckBox from '../../Buttons/CheckBox';
import SelectButton from '../../Buttons/SelectButton';
import RangeSlider from 'react-range-slider-input';
import 'react-range-slider-input/dist/style.css';
import './RangeSliderStyles.css';
import ButtonGroup from '../../Buttons/ButtonGroup';
import airportsData from '../../Search/SearchFlights/InputSearch/airports.json';
import {airportPros} from '../../Search/SearchFlights/InputSearch/types';
import {
  AgentsFilterProps,
  AirlinesFilterProps,
  AirportsFilterProps,
} from '../../../store/flights/types';
import airlines from '../../../core/constants/airlines/airlines.json';
import ViewportChecker from '../../../core/helpers/ViewPort';
import PassengersSelector from '../../Buttons/PassengersSelector';
import Skeleton from '../Skeleton';
import {FlightCardMobileSkeleton} from '../Skeleton/Skeletons';
import {isSearchUrl} from '../../../core/helpers/String';
import {getEnvironment} from '../../../core/helpers/Environments';

const dataFligths: any = JSONdata;

interface BodyContentProps {
  callSearchItent: number;
}

function BodyContent({callSearchItent}: BodyContentProps) {
  const isMobile = ViewportChecker();
  const inlineData = useSelector(getInlineData);
  const totalExpectedResponses = useSelector(getTotalExpectedResponses);
  const currentResponsesCount = useSelector(getCurrentResponsesCount);
  const isLoadingRequestFlights = useSelector(getIsLoadingRequestFlights);
  const airportsFilter = useSelector(getAirportsFilter);
  const sideSearchData = useSelector(getSideSearchData);
  const isSearchFinished = useSelector(getIsSearchFinished);
  const flightsFilter = useSelector(getFlightsFilter);
  const searchItent = useSelector(getSearchIntent);
  const progressResultPercentage = useSelector(getProgressResultPercentage);
  const [flightsFilteredList, setFlightsFilteredList] = useState<
    flightDataProps[]
  >([]);
  const [percentProgess, setPercentProgess] = useState<any>([]);
  const sksFlights = useSelector(getSksFlights);
  const [currentPage, setCurrentPage] = useState(0);
  const [isShowMobileFilter, handlerMobileFilter] = useState(false);
  const itemsPerPage = 50;
  const offset = currentPage * itemsPerPage;
  const [hasZeroFlightsResults, setHasZeroFlightsResults] = useState(false);
  const limitWaitWithoutResults: number = 30000;
  const [isShowBannerfilter, setHandlerShowBannerfilter] = useState(false);
  const [hasShowInline, setShowInline] = useState(false);
  const [hasShowSideSearch, setShowSideSearch] = useState(false);
  const [isFlightModalVisibile, handlerFlightModalVisibile] = useState(false);
  const [flightCardItem, setFlightCardItem] = useState<flightDataProps>();
  const divRef = useRef<HTMLDivElement>(null);
  const detailsCardModalRef = useRef<HTMLDivElement>(null);
  const resultAnchorRef = useRef<HTMLDivElement>(null);
  const resultAnchorMobileRef = useRef<HTMLDivElement>(null);
  const [searchItentDepartureDatesObject, setSearchItentDepartureDatesObject] =
    useState<CalendarDateProps[] | []>([]);
  const [searchItentReturnDatesObject, setSearchItentReturnDatesObject] =
    useState<CalendarDateProps[] | []>([]);
  const innerDivRef = useRef<HTMLDivElement>(null);
  const [isOnlyDirectStopsChecked, handlerOnlyDirectStopsChecked] =
    useState(false);
  const [isShowFilterModal, handlerShowFilterModal] = useState(false);

  //Filters
  const minuteMax = 1440;
  const [isDirectFlights, handlerDirectFlights] = useState(true);
  const [isOneStopsFlights, handlerOneStopsFlights] = useState(true);
  const [isTwoStopsFlights, handlerTwoStopsFlights] = useState(true);
  const [isThreeStopsFlights, handlerThreeStopsFlights] = useState(true);
  const [outboundTimeMin, setOutboundTimeMin] = useState(0);
  const [outboundTimeMax, setOutboundTimeMax] = useState(1439);
  const [outboundTimeMinSaved, setOutboundTimeMinSaved] = useState(0);
  const [outboundTimeMaxSaved, setOutboundTimeMaxSaved] = useState(1439);
  const [priceMin, setPriceMin] = useState(0);
  const [priceMax, setPriceMax] = useState(1439);
  const [priceMinSaved, setPriceMinSaved] = useState(0);
  const [priceMaxSaved, setPriceMaxSaved] = useState(1439);
  const [returnTimeMin, setReturnTimeMin] = useState(0);
  const [returnTimeMax, setReturnTimeMax] = useState(1439);
  const [returnTimeMinSaved, setReturnTimeMinSaved] = useState(0);
  const [returnTimeMaxSaved, setReturnTimeMaxSaved] = useState(1439);
  const [isDirectFilterApplied, handlerDirectFilterApplied] = useState(false);
  const [isStopFilterApplied, handlerStopFilterApplied] = useState(false);
  const [isPriceFilterApplied, handlerPriceFilterApplied] = useState(false);
  const [isAirportFilterApplied, handlerAirportFilterApplied] = useState(false);
  const [isDepartureTimeFilterApplied, handlerDepartureTimeFilterApplied] =
    useState(false);
  const [isReturnTimeFilterApplied, handlerReturnTimeFilterApplied] =
    useState(false);
  const [isAirlineFilterApplied, handlerAirlineFilterApplied] = useState(false);
  const [isAgentFilterApplied, handlerAgentFilterApplied] = useState(false);
  const [countFiltersApplied, setCountFiltersApplied] = useState(0);
  const isOneWayTrip = useSelector(getIsOneWayTrip);

  const [airlinesFilterList, setAirlinesFilterList] = useState<
    AirlinesFilterProps[]
  >([]);
  const [airlinesFilterListSaved, setAirlinesFilterListSaved] = useState<
    AirlinesFilterProps[]
  >([]);

  const [agentsFilterList, setAgentsFilterList] = useState<AgentsFilterProps[]>(
    [],
  );
  const [agentsFilterListSaved, setAgentsFilterListSaved] = useState<
    AgentsFilterProps[]
  >([]);

  const [airportsFilterList, setAirportsFilterList] =
    useState<AirportsFilterProps[]>(airportsFilter);

  const [airportsFilterListSaved, setAirportsFilterListSaved] =
    useState<AirportsFilterProps[]>(airportsFilter);

  const agentsFilterSelector = useSelector(getAgentsFilter);
  const airlinesFilterSelector = useSelector(getAirlinesFilter);

  const divSerarHeader1Ref = useRef<HTMLDivElement>(null);
  const [div1Width, setDiv1Width] = useState(0);
  const [div2Width, setDiv2Width] = useState(0);

  const numberOfPassengers = useSelector(getNumberOfPassengers);
  const [introToggles, setIntroToggles] = useState<{[key: number]: boolean}>({
    1: true,
    2: true,
    3: true, // valores de exemplo
  });

  // Função que recebe um número (index) e inverte o valor booleano associado
  const toggleIntro = (index: number) => {
    setIntroToggles(prevState => ({
      ...prevState,
      [index]: !prevState[index], // Inverte o valor booleano
    }));
  };

  useEffect(() => {
    const updateDiv2Width = () => {
      if (divSerarHeader1Ref.current) {
        const newWidth = divSerarHeader1Ref.current.offsetWidth;
        setDiv1Width(newWidth);
      }
    };
    updateDiv2Width();

    window.addEventListener('resize', updateDiv2Width);

    return () => {
      window.removeEventListener('resize', updateDiv2Width);
    };
  }, []);

  type RefsType = {
    [key: string]: React.RefObject<HTMLDivElement>;
  };

  const refs: RefsType = {
    section1: useRef<HTMLDivElement>(null),
    section2: useRef<HTMLDivElement>(null),
    section3: useRef<HTMLDivElement>(null),
    section4: useRef<HTMLDivElement>(null),
    section5: useRef<HTMLDivElement>(null),
    section6: useRef<HTMLDivElement>(null),
  };
  const scrollToElement = (refName: keyof RefsType) => {
    setTimeout(() => {
      const ref = refs[refName];
      if (ref?.current) {
        ref.current.scrollIntoView({behavior: 'smooth', block: 'start'});
      }
    }, 100);
  };

  const dispatch = useDispatch();
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const totalSearchItent = callSearchItent * 10;
    if (progressResultPercentage < 10) {
      const intervalId = setInterval(() => {
        setProgress(prevProgress => {
          if (prevProgress < 10) {
            return prevProgress + 1;
          } else {
            clearInterval(intervalId);
            return prevProgress;
          }
        });
      }, 250);

      return () => clearInterval(intervalId);
    } else {
      setProgress(totalSearchItent);
    }
  }, [callSearchItent, progressResultPercentage]);

  useEffect(() => {
    if (searchItent?.departureDate.length) {
      setSearchItentDepartureDatesObject(
        convertArrayStringToObjects(searchItent?.departureDate),
      );
    }

    if (searchItent?.returnDate?.length) {
      setSearchItentReturnDatesObject(
        convertArrayStringToObjects(searchItent?.returnDate),
      );
    }
  }, [searchItent]);

  useEffect(() => {
    setAirportsFilterList(airportsFilter);
  }, [airportsFilter]);

  useEffect(() => {
    if (sideSearchData?.length && !hasShowSideSearch) {
      sideSearchData.map((item, index) => {
        const event = {
          event: 'sidesearch_impression-staging',
          seller: item?.partner_description,
          route: item?.url,
        };

        window.dataLayer.push(event);
      });

      setShowSideSearch(true);
    }
  }, [sideSearchData, inlineData]);

  useEffect(() => {
    if (inlineData?.id && !hasShowInline) {
      setShowInline(true);
      const event = {
        event: 'inline_impression-staging',
        seller: inlineData.id,
        route: inlineData.url,
      };

      window.dataLayer.push(event);
    }
  }, [inlineData]);

  useEffect(() => {
    const handleScroll = () => {
      if (innerDivRef.current) {
        const rect = innerDivRef.current.getBoundingClientRect();
        if (rect.top <= 0) {
          // setIsFixed(true);
        } else {
          // setIsFixed(false);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Limpeza do evento de rolagem ao desmontar o componente
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const [
      isDirect,
      isOneStops,
      isTowStops,
      priceMin,
      priceMax,
      departureTimeMin,
      departureTimeMax,
      returnTimeMin,
      returnTimeMax,
    ] = [
      flightsFilter.stops.direct,
      flightsFilter.stops.oneStop,
      flightsFilter.stops.twoStops,
      flightsFilter.price.min,
      flightsFilter.price.max,
      flightsFilter.departureTime.min,
      flightsFilter.departureTime.max,
      flightsFilter.returnTime.min,
      flightsFilter.returnTime.max,
    ];

    const initalPriceMin = parseInt(sksFlights[0]?.pricingOptions[0]?.price);

    const initalPriceMax = parseInt(
      sksFlights[sksFlights.length - 1]?.pricingOptions[0]?.price,
    );

    if (isDirect && isOneStops && isTowStops && !isOnlyDirectStopsChecked) {
      handlerStopFilterApplied(false);
    } else {
      handlerStopFilterApplied(true);
    }

    if (
      airportsFilter.some(airportsFilterItem => {
        return airportsFilterItem.isChecked === false;
      })
    ) {
      handlerAirportFilterApplied(true);
    } else {
      handlerAirportFilterApplied(false);
    }

    if (
      airlinesFilterListSaved.some(airlinesFilterItem => {
        return airlinesFilterItem.isChecked === false;
      })
    ) {
      handlerAirlineFilterApplied(true);
    } else {
      handlerAirlineFilterApplied(false);
    }

    // console.log('filter-initalPriceMin = ', initalPriceMin);
    // console.log('filter-initalPriceMax = ', initalPriceMax);
    // console.log('filter-priceMin = ', priceMin);
    // console.log('filter-priceMax = ', priceMax);

    if (priceMin > initalPriceMin || priceMax < initalPriceMax) {
      handlerPriceFilterApplied(true);
    } else {
      handlerPriceFilterApplied(false);
    }

    // console.log('filter-departureTimeMin = ', departureTimeMin);
    // console.log('filter-departureTimeMax = ', departureTimeMin);

    if (
      departureTimeMin > 0 ||
      departureTimeMax < minuteMax - 1 ||
      returnTimeMin > 0 ||
      returnTimeMax < minuteMax - 1
    ) {
      handlerDepartureTimeFilterApplied(true);
    } else {
      handlerDepartureTimeFilterApplied(false);
    }

    if (
      agentsFilterListSaved.some(agentsFilterItem => {
        return agentsFilterItem.isChecked === false;
      })
    ) {
      handlerAgentFilterApplied(true);
    } else {
      handlerAgentFilterApplied(false);
    }
  }, [flightsFilter, airportsFilterListSaved, airlinesFilterListSaved]);

  useEffect(() => {
    const filterApplied = [
      isStopFilterApplied,
      isPriceFilterApplied,
      isAirlineFilterApplied,
      isAirportFilterApplied,
      isDepartureTimeFilterApplied,
      isAgentFilterApplied,
    ].filter(item => item === true);

    // console.log('isStopFilterApplied = ', isStopFilterApplied);
    // console.log('isPriceFilterApplied = ', isPriceFilterApplied);
    // console.log('isAirlineFilterApplied = ', isAirlineFilterApplied);
    // console.log('isAirportFilterApplied = ', isAirportFilterApplied);
    // console.log('isDepartureTimeFilterApplied = ', isDepartureTimeFilterApplied);
    setCountFiltersApplied(filterApplied.length);
  }, [
    isStopFilterApplied,
    isPriceFilterApplied,
    isAirlineFilterApplied,
    isAirportFilterApplied,
    isDepartureTimeFilterApplied,
    isAgentFilterApplied,
  ]);

  useEffect(() => {
    // Adiciona um pequeno atraso para garantir que o componente seja renderizado

    const timer = setTimeout(() => {
      if (resultAnchorRef.current && !isMobile) {
        resultAnchorRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }

      if (
        resultAnchorMobileRef.current &&
        isMobile &&
        searchItent?.departureDate
      ) {
        resultAnchorMobileRef.current.scrollIntoView({
          behavior: isSearchFinished ? 'smooth' : 'smooth',
          block: 'start',
        });
      }
    }, 100); // Ajuste o atraso conforme necessário

    // Limpa o timeout se o componente for desmontado antes do timeout completar
    return () => clearTimeout(timer);
  }, [searchItent?.departureDate]);

  const handlePageClick = (selected: any) => {
    setCurrentPage(selected.selected);
    var divTest = document.querySelector('.div-content') as HTMLElement;

    if (divTest) {
      var offsetTop = divTest.offsetTop;
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  };

  const getImage = (itemOfferTip: offerTipPostProps) => {
    const image = itemOfferTip.media.filter(
      item => itemOfferTip.meta.featured_image === item.id,
    );

    return image[0]?.sizes[2]?.url ?? cardImageDefault;
  };

  const getCreatedAt = (itemOfferTip: offerTipPostProps) => {
    const createdAtMinutes = convertStrigToMinutes(itemOfferTip.date);
    let returnTime = 0;
    let returnTimeLabel = '';

    if (createdAtMinutes < 60) {
      returnTime = createdAtMinutes;

      returnTimeLabel = `${Math.trunc(returnTime)} ${
        Math.trunc(returnTime) > 1 ? 'minutos' : 'minuto'
      }`;
    } else if (createdAtMinutes / 60 < 24) {
      returnTime = createdAtMinutes / 60;

      returnTimeLabel = `${Math.trunc(returnTime)} ${
        Math.trunc(returnTime) > 1 ? 'horas' : 'hora'
      }`;
    } else {
      returnTime = createdAtMinutes / 60 / 24;

      returnTimeLabel = `${Math.trunc(returnTime)} ${
        Math.trunc(returnTime) > 1 ? 'dias' : 'dia'
      }`;
    }

    return `Há ${returnTimeLabel}`;
  };

  function removeDuplicates(arr: any) {
    const uniqueObjects: any = [];
    const uniqueKeys = new Set();

    for (const obj of arr) {
      const objString = JSON.stringify(obj);

      if (!uniqueKeys.has(objString)) {
        uniqueKeys.add(objString);
        uniqueObjects.push(obj);
      }
    }

    return uniqueObjects;
  }

  useEffect(() => {
    setFlightsFilteredList(removeDuplicates(sksFlights));

    let flightAirlines: any[] = [];
    let flightAgents: any[] = [];

    sksFlights.map((flight: flightDataProps) => {
      flight?.outboundFlights.flightsDetails.map(flightsDetailsItem => {
        const airlineDataFilter = airlines.filter(
          airlinesItem => airlinesItem.id === flightsDetailsItem.airline,
        )[0];

        if (airlineDataFilter?.name) {
          flightAirlines.push({
            airlineCode: flightsDetailsItem.airline,
            airlineName: airlineDataFilter?.name,
            isChecked: true,
          });
        }
      });

      flight?.returnFlights?.flightsDetails.map(flightsDetailsItem => {
        const airlineDataFilter = airlines.filter(
          airlinesItem => airlinesItem.id === flightsDetailsItem.airline,
        )[0];

        if (airlineDataFilter?.name) {
          flightAirlines.push({
            airlineCode: flightsDetailsItem.airline,
            airlineName: airlineDataFilter?.name,
            isChecked: true,
          });
        }
      });
    });

    const airlinesList = removeDuplicates(
      flightAirlines.sort((a, b) => {
        if (a.airlineName < b.airlineName) {
          return -1;
        }
        if (a.airlineName > b.airlineName) {
          return 1;
        }
        return 0;
      }),
    );

    setAirlinesFilterList(airlinesList);
    setAirlinesFilterListSaved(airlinesList);

    sksFlights.map((flight: flightDataProps) => {
      flight?.pricingOptions.map((item, index) => {
        flightAgents.push({
          agentLogo: item.agent?.logo,
          agentName: item.agent?.name,
          isChecked: true,
        });
      });
    });

    function removeDuplicatesByAgentName(
      arr: AgentsFilterProps[],
    ): AgentsFilterProps[] {
      const uniqueObjects: AgentsFilterProps[] = [];
      const uniqueNames = new Set();

      for (const obj of arr) {
        if (!uniqueNames.has(obj.agentName)) {
          uniqueNames.add(obj.agentName);
          uniqueObjects.push(obj);
        }
      }

      return uniqueObjects;
    }

    const airlinesAgents = removeDuplicatesByAgentName(
      flightAgents.sort((a, b) => {
        if (a.agentName < b.agentName) {
          return -1;
        }
        if (a.agentName > b.agentName) {
          return 1;
        }
        return 0;
      }),
    );

    // console.log("airlinesAgents = ", airlinesAgents);

    const [priceMinValue, priceMaxValue] = [
      sksFlights[0]?.pricingOptions[0]?.price,
      sksFlights[sksFlights.length - 1]?.pricingOptions[0]?.price,
    ];

    setPriceMinSaved(priceMinValue);
    setPriceMaxSaved(priceMaxValue);
    setPriceMin(priceMinValue);
    setPriceMax(priceMaxValue);

    setAgentsFilterList(airlinesAgents);
    setAgentsFilterListSaved(airlinesAgents);

    dispatch(setAgentsFilter({agentsFilter: airlinesAgents}));
    // dispatch(setAirlinesFilter({airlinesFilter: airlinesFilterList}));

    dispatch(
      setFlightsFilter({
        flightsFilter: {
          stops: {
            direct: isDirectFlights,
            oneStop: isOneStopsFlights,
            twoStops: isTwoStopsFlights,
          },
          departureTime: {
            min: outboundTimeMin,
            max: outboundTimeMax,
          },
          returnTime: {
            min: returnTimeMin,
            max: returnTimeMax,
          },
          price: {
            min: parseInt(sksFlights[0]?.pricingOptions[0]?.price),
            max: parseInt(
              sksFlights[sksFlights.length - 1]?.pricingOptions[0]?.price,
            ),
          },
          flightDuration: 1000000,
        },
      }),
    );
  }, [sksFlights]);

  useEffect(() => {
    if (Math.round(percentProgess) >= 100) {
      let timeoutId: any;

      const handleVariavelChange = () => {
        clearTimeout(timeoutId);

        timeoutId = setTimeout(() => {
          const filteredFlights = sksFlights.filter(
            (flight: flightDataProps) => {
              const hasReturn = flight?.returnFlights ? true : false;

              const outboundStops = flight.outboundFlights.flight.stops;
              const returnStops = hasReturn
                ? flight.returnFlights.flight.stops
                : 0;
              if (
                (flightsFilter.stops.direct &&
                  !flightsFilter.stops.oneStop &&
                  !flightsFilter.stops.twoStops &&
                  outboundStops + returnStops === 0) ||
                (flightsFilter.stops.oneStop &&
                  !flightsFilter.stops.direct &&
                  !flightsFilter.stops.twoStops &&
                  outboundStops === 1 &&
                  returnStops === 1) ||
                (flightsFilter.stops.twoStops &&
                  !flightsFilter.stops.direct &&
                  !flightsFilter.stops.oneStop &&
                  outboundStops >= 2 &&
                  returnStops >= 2) ||
                (flightsFilter.stops.direct &&
                  flightsFilter.stops.oneStop &&
                  !flightsFilter.stops.twoStops &&
                  outboundStops <= 1 &&
                  returnStops <= 1) ||
                (!flightsFilter.stops.direct &&
                  flightsFilter.stops.oneStop &&
                  flightsFilter.stops.twoStops &&
                  outboundStops >= 1 &&
                  returnStops >= 1) ||
                (flightsFilter.stops.direct &&
                  !flightsFilter.stops.oneStop &&
                  flightsFilter.stops.twoStops &&
                  (outboundStops === 0 || outboundStops >= 2) &&
                  (returnStops === 0 || returnStops >= 2)) ||
                (flightsFilter.stops.direct &&
                  flightsFilter.stops.oneStop &&
                  flightsFilter.stops.twoStops)
              ) {
                const outboundDepartureTime =
                  parseInt(
                    flight.outboundFlights.flight.departureTime.split(':')[0],
                  ) *
                    60 +
                  parseInt(
                    flight.outboundFlights.flight.departureTime.split(':')[1],
                  );

                const returnDepartureTime = hasReturn
                  ? parseInt(
                      flight.returnFlights.flight.departureTime.split(':')[0],
                    ) *
                      60 +
                    parseInt(
                      flight.returnFlights.flight.departureTime.split(':')[1],
                    )
                  : undefined;

                if (
                  flightsFilter.departureTime.min <= outboundDepartureTime &&
                  outboundDepartureTime <= flightsFilter.departureTime.max &&
                  (returnDepartureTime === undefined ||
                    (flightsFilter.returnTime.min <= returnDepartureTime &&
                      returnDepartureTime <= flightsFilter.returnTime.max))
                ) {
                  const outboundDuration =
                    flight.outboundFlights.flight.durationFlight;
                  const returnDuration = hasReturn
                    ? flight.returnFlights.flight.durationFlight
                    : undefined;

                  const price = parseInt(flight.pricingOptions[0].price);

                  if (
                    price >= flightsFilter.price.min &&
                    price <= flightsFilter.price.max
                  ) {
                    if (
                      outboundDuration <= flightsFilter.flightDuration &&
                      (returnDuration === undefined ||
                        returnDuration <= flightsFilter.flightDuration)
                    ) {
                      if (
                        !airportsFilter
                          .filter(
                            airportFilterItem =>
                              airportFilterItem.isChecked === false,
                          )
                          .some(
                            airportFilterItem =>
                              airportFilterItem.iata ===
                                flight.outboundFlights.flight.originAirport ||
                              airportFilterItem.iata ===
                                flight.outboundFlights.flight
                                  .destinationAirport ||
                              airportFilterItem.iata ===
                                flight?.returnFlights?.flight?.originAirport ||
                              airportFilterItem.iata ===
                                flight?.returnFlights?.flight
                                  ?.destinationAirport,
                          )
                      ) {
                        if (
                          !airlinesFilterSelector
                            .filter(
                              airlinesFilterItem =>
                                airlinesFilterItem.isChecked === false,
                            )
                            .some(
                              airlinesFilterItem =>
                                flight?.outboundFlights.flightsDetails.some(
                                  flightsDetailsItem =>
                                    flightsDetailsItem.airline ===
                                    airlinesFilterItem.airlineCode,
                                ) ||
                                flight?.returnFlights?.flightsDetails.some(
                                  flightsDetailsItem =>
                                    flightsDetailsItem.airline ===
                                    airlinesFilterItem.airlineCode,
                                ),
                            )
                        )
                          if (
                            flight.pricingOptions.some(pricingOptionsItem =>
                              agentsFilterSelector
                                .filter(
                                  agentsFilterSelectorItem =>
                                    agentsFilterSelectorItem.isChecked,
                                )
                                .some(
                                  agentsFilterSelectorItem =>
                                    agentsFilterSelectorItem.agentName ===
                                    pricingOptionsItem.agent.name,
                                ),
                            )
                          ) {
                            return true;
                          }
                      }
                    }
                  }
                }
              }

              return false;
            },
          );

          setFlightsFilteredList(filteredFlights.sort(sortByPrice));

          setCurrentPage(0);
        }, 200);
      };

      handleVariavelChange();

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [flightsFilter, airportsFilter]);

  const handleClick = (e: any) => {
    if (divRef.current === e.target) {
      handlerMobileFilter(false);
    }
  };

  useEffect(() => {
    if (isSearchFinished && sksFlights.length === 0) {
      setHasZeroFlightsResults(true);
    }
  }, [isSearchFinished, sksFlights]);

  useEffect(() => {
    isSearchFinished && setPercentProgess(100);
  }, [isSearchFinished]);

  useEffect(() => {
    isLoadingRequestFlights && setHandlerShowBannerfilter(true);
  }, [isLoadingRequestFlights]);

  useEffect(() => {
    if (isFlightModalVisibile || isShowFilterModal) {
      document.body.style.overflowY = 'hidden';
    } else {
      document.body.style.overflowY = 'auto';
    }
  }, [isFlightModalVisibile, isShowFilterModal]);

  const applyOnlyDirectStops = (isCheked: boolean) => {
    if (!isCheked) {
      handlerOnlyDirectStopsChecked(isCheked);
      handlerDirectFlights(true);
      handlerOneStopsFlights(true);
      handlerTwoStopsFlights(true);
      handlerThreeStopsFlights(true);

      // dispatch(setAirportsFilter({airportsFilter: airportsFilterList}));

      dispatch(
        setFlightsFilter({
          flightsFilter: {
            stops: {
              direct: true,
              oneStop: true,
              twoStops: true,
            },
            departureTime: {
              min: outboundTimeMin,
              max: outboundTimeMax,
            },
            returnTime: {
              min: returnTimeMin,
              max: returnTimeMax,
            },
            price: {
              min:
                priceMin ?? parseInt(sksFlights[0]?.pricingOptions[0]?.price),
              max:
                priceMax ??
                parseInt(
                  sksFlights[sksFlights.length - 1]?.pricingOptions[0]?.price,
                ),
            },
            flightDuration: 1000000,
          },
        }),
      );
    } else {
      // console.log("isOnlyDirectStopsChecked 222 = ", isCheked);

      handlerOnlyDirectStopsChecked(isCheked);

      handlerDirectFlights(true);
      handlerOneStopsFlights(false);
      handlerTwoStopsFlights(false);
      handlerThreeStopsFlights(false);

      // dispatch(setAirportsFilter({airportsFilter: airportsFilterList}));

      dispatch(
        setFlightsFilter({
          flightsFilter: {
            stops: {
              direct: true,
              oneStop: false,
              twoStops: false,
            },
            departureTime: {
              min: outboundTimeMin,
              max: outboundTimeMax,
            },
            returnTime: {
              min: returnTimeMin,
              max: returnTimeMax,
            },
            price: {
              min:
                priceMin ?? parseInt(sksFlights[0]?.pricingOptions[0]?.price),
              max:
                priceMax ??
                parseInt(
                  sksFlights[sksFlights.length - 1]?.pricingOptions[0]?.price,
                ),
            },
            flightDuration: 1000000,
          },
        }),
      );

      // applyFilter();
    }
  };

  const getAirportData = (airportCode: string): airportPros => {
    const airpotData = airportsData.filter(
      item => item.airport_code === airportCode,
    )[0];

    return airpotData;
  };

  const changeAirportsFilter = (
    item: AirportsFilterProps,
    isCheck: boolean,
  ) => {
    const newItem = {...item, isChecked: isCheck};

    const newAirportsFilter = airportsFilterList.map(airportItem => {
      if (airportItem.iata === newItem.iata) {
        return newItem;
      } else {
        return airportItem;
      }
    });

    setAirportsFilterList(newAirportsFilter);
  };

  const changeAirlinesFilter = (
    item: AirlinesFilterProps,
    isChecked: boolean,
  ) => {
    const newItem = {...item, isChecked};

    const newAirlinesFilterList = airlinesFilterList.map(airlineItem => {
      if (airlineItem.airlineCode === newItem.airlineCode) {
        return newItem;
      } else {
        return airlineItem;
      }
    });

    setAirlinesFilterList(newAirlinesFilterList);
  };

  const changeAgentsFilter = (item: AgentsFilterProps, isChecked: boolean) => {
    const newItem = {...item, isChecked};

    const newAgentFilterList = agentsFilterList.map(agentItem => {
      if (agentItem.agentName === newItem.agentName) {
        return newItem;
      } else {
        return agentItem;
      }
    });

    setAgentsFilterList(newAgentFilterList);
  };

  const applyFilter = () => {
    dispatch(
      setFlightsFilter({
        flightsFilter: {
          stops: {
            direct: isDirectFlights,
            oneStop: isOneStopsFlights,
            twoStops: isTwoStopsFlights,
          },
          departureTime: {
            min: outboundTimeMin,
            max: outboundTimeMax,
          },
          returnTime: {
            min: returnTimeMin,
            max: returnTimeMax,
          },
          price: {
            min: priceMin ?? parseInt(sksFlights[0]?.pricingOptions[0]?.price),
            max:
              priceMax ??
              parseInt(
                sksFlights[sksFlights.length - 1]?.pricingOptions[0]?.price,
              ),
          },
          flightDuration: 1000000,
        },
      }),
    );

    setPriceMinSaved(priceMin);
    setPriceMaxSaved(priceMax);
    setOutboundTimeMinSaved(outboundTimeMin);
    setOutboundTimeMaxSaved(outboundTimeMax);
    setReturnTimeMinSaved(returnTimeMin);
    setReturnTimeMaxSaved(returnTimeMax);

    setAirlinesFilterListSaved(airlinesFilterList);
    setAgentsFilterListSaved(agentsFilterList);
    dispatch(setAgentsFilter({agentsFilter: agentsFilterList}));
    dispatch(setAirlinesFilter({airlinesFilter: airlinesFilterList}));
    dispatch(setAirportsFilter({airportsFilter: airportsFilterList}));

    // console.log('agentsFilterList', agentsFilterList);

    handlerShowFilterModal(false);
  };

  const clearFilter = () => {
    applyOnlyDirectStops(false);
    const defautlPriceMin = parseInt(sksFlights[0]?.pricingOptions[0]?.price);
    const defautlPriceMax = parseInt(
      sksFlights[sksFlights.length - 1]?.pricingOptions[0]?.price,
    );

    setPriceMin(defautlPriceMin);
    setPriceMax(defautlPriceMax);
    setPriceMinSaved(defautlPriceMin);
    setPriceMaxSaved(defautlPriceMax);

    setOutboundTimeMin(0);
    setOutboundTimeMax(1439);
    setOutboundTimeMinSaved(0);
    setOutboundTimeMaxSaved(1439);

    setReturnTimeMin(0);
    setReturnTimeMax(1439);
    setReturnTimeMinSaved(0);
    setReturnTimeMaxSaved(1439);

    const newAirportsFilterList = airportsFilterList.map(
      airportsFilterItem => ({
        ...airportsFilterItem,
        isChecked: true,
      }),
    );

    setAirportsFilterList(newAirportsFilterList);
    dispatch(setAirportsFilter({airportsFilter: newAirportsFilterList}));

    const newAirlinesFilterList = airlinesFilterList.map(
      airlinesFilterItem => ({
        ...airlinesFilterItem,
        isChecked: true,
      }),
    );

    setAirlinesFilterList(newAirlinesFilterList);
    setAirlinesFilterListSaved(newAirlinesFilterList);
    dispatch(setAirlinesFilter({airlinesFilter: newAirlinesFilterList}));

    const newAgentsFilterList = agentsFilterList.map(agentsFilterItem => ({
      ...agentsFilterItem,
      isChecked: true,
    }));

    setAgentsFilterList(newAgentsFilterList);
    setAgentsFilterListSaved(newAgentsFilterList);

    dispatch(
      setFlightsFilter({
        flightsFilter: {
          stops: {
            direct: true,
            oneStop: true,
            twoStops: true,
          },
          departureTime: {
            min: 0,
            max: minuteMax,
          },
          returnTime: {
            min: 0,
            max: minuteMax,
          },
          price: {
            min: parseInt(sksFlights[0]?.pricingOptions[0]?.price),
            max: parseInt(
              sksFlights[sksFlights.length - 1]?.pricingOptions[0]?.price,
            ),
          },
          flightDuration: 1000000,
        },
      }),
    );
  };

  useEffect(() => {
    if (!isMobile) {
      applyFilter();
    }
  }, [
    isDirectFlights,
    isOneStopsFlights,
    isTwoStopsFlights,
    isThreeStopsFlights,
    priceMin,
    priceMax,
    airportsFilterList,
    outboundTimeMin,
    outboundTimeMax,
    returnTimeMin,
    returnTimeMax,
    airlinesFilterList,
    agentsFilterList,
  ]);

  const environmentType = getEnvironment();

  const getUniqueAirlines = (
    flightData: flightDataProps | null | undefined,
  ): string[] => {
    try {
      if (!flightData) return [];
      const extractAirlines = (
        flightsDetails: flightDetailsProps[] | undefined,
      ): string[] => {
        if (!flightsDetails || !Array.isArray(flightsDetails)) return [];
        return flightsDetails
          .map(detail => detail?.airline) // Extract airline, considering undefined or null
          .filter((airline): airline is string => typeof airline === 'string'); // Filter valid strings
      };
      const outboundAirlines = extractAirlines(
        flightData.outboundFlights?.flightsDetails,
      );
      const returnAirlines = extractAirlines(
        flightData.returnFlights?.flightsDetails,
      );
      // Combine and get unique values
      return Array.from(new Set([...outboundAirlines, ...returnAirlines]));
    } catch (error) {
      console.error('getUniqueAirlines', error);
      return [];
    }
  };
  const handleGTMFlightCardEvent = (
    flightItem: flightDataProps,
    resultsPosition: number,
    page: number,
    airlines: string[],
    minPrice: number,
  ) => {
    try {
      const event = {
        event: `flightCard-${environmentType}`,
        resultsPosition: resultsPosition,
        page: page,
        airlines: airlines,
        minPrice: minPrice,
        IATA_origin: flightItem.outboundFlights?.flight?.originAirport ?? '',
        IATA_destination:
          flightItem.outboundFlights?.flight?.destinationAirport ?? '',
        departure_date: flightItem.outboundFlights?.flight.departureDate ?? '',
        return_date: flightItem?.returnFlights?.flight?.departureDate ?? '',
      };

      window.dataLayer.push(event);
    } catch (error) {
      console.error(`Erro em GTM flightCard-${environmentType}`, error);
    }
  };

  const getFilterComponent = () => {
    return (
      <div className="div-fiters-content">
        <div className="div-fiters-element">
          <div className="div-title-fiters-element">
            <Icon iconName="plane-taking-off" />
            <span ref={refs.section1} className="text-title-fiters-element">
              PARADAS
            </span>
          </div>

          <div className="div-body-fiters-element">
            <div className="div-body-fiters-element-left">
              <div className="div-body-fiters-element-item">
                <CheckBox
                  isChecked={isDirectFlights}
                  checkedBackgroundColor="#00688D"
                  checkedIconColor="#ffffff"
                  onClick={() => handlerDirectFlights(!isDirectFlights)}
                />
                <span
                  className="text-body-fiters-element-item"
                  style={{color: '#219653'}}>
                  Direto
                </span>
              </div>

              <div className="div-body-fiters-element-item">
                <CheckBox
                  isChecked={isOneStopsFlights}
                  checkedBackgroundColor="#00688D"
                  checkedIconColor="#ffffff"
                  onClick={() => handlerOneStopsFlights(!isOneStopsFlights)}
                />
                <span
                  className="text-body-fiters-element-item"
                  style={{color: '#F2994A'}}>
                  1 Parada
                </span>
              </div>
            </div>

            <div className="div-body-fiters-element-right">
              <div className="div-body-fiters-element-item">
                <CheckBox
                  isChecked={isTwoStopsFlights}
                  checkedBackgroundColor="#00688D"
                  checkedIconColor="#ffffff"
                  onClick={() => handlerTwoStopsFlights(!isTwoStopsFlights)}
                />
                <span
                  className="text-body-fiters-element-item"
                  style={{color: '#EB5757'}}>
                  2 Paradas
                </span>
              </div>
              <div className="div-body-fiters-element-item">
                <CheckBox
                  isChecked={isThreeStopsFlights}
                  checkedBackgroundColor="#00688D"
                  checkedIconColor="#ffffff"
                  onClick={() => handlerThreeStopsFlights(!isThreeStopsFlights)}
                />
                <span
                  className="text-body-fiters-element-item"
                  style={{color: '#EB5757'}}>
                  3+ Paradas
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="div-dashed-line-fiters" />

        <div ref={refs.section2} className="div-fiters-element">
          <div className="div-title-fiters-element">
            <Icon iconName="cash" />
            <span className="text-title-fiters-element">PREÇO</span>
          </div>

          <div className="div-body-fiters-element-column">
            <div className="div-body-fiters-element-top">
              <span className="text-body-fiters-element-top-item">Entre</span>
              <span className="text-body-fiters-element-top-item-bold">{`\u00A0R$ ${
                parseAmout(priceMin).beforeCents
              }\u00A0`}</span>
              {/* <span className="text-body-fiters-element-top-item-bold">{`\u00A0R$ 758,00\u00A0`}</span> */}
              <span className="text-body-fiters-element-top-item">e</span>
              <span className="text-body-fiters-element-top-item-bold">{`\u00A0R$ ${
                parseAmout(priceMax).beforeCents
              }`}</span>
            </div>
            <div className="div-slider-filter">
              <RangeSlider
                className="slider-filter"
                min={parseInt(sksFlights[0]?.pricingOptions[0]?.price)}
                max={parseInt(
                  sksFlights[sksFlights.length - 1]?.pricingOptions[0]?.price,
                )}
                step={1}
                value={[priceMin, priceMax]}
                defaultValue={[priceMinSaved, priceMaxSaved]}
                onInput={(values: any) => {
                  setPriceMin(values[0]);
                  setPriceMax(values[1]);
                }}
              />
            </div>
          </div>
        </div>

        <div className="div-dashed-line-fiters" />

        <div ref={refs.section3} className="div-fiters-element">
          <div className="div-title-fiters-element">
            <Icon iconName="plane-taking-off" />
            <span className="text-title-fiters-element">{`${getAirportData(
              searchItent?.originAirport ?? '',
            )?.city_name.toUpperCase()}`}</span>
          </div>

          <div className="div-body-fiters-element-airports">
            <div className="div-airports-list-fiters-element">
              {airportsFilterList
                .filter(newAirportsFilterCheckedItem => {
                  const airportData = getAirportData(
                    newAirportsFilterCheckedItem.iata,
                  );
                  const originAirportData = getAirportData(
                    searchItent?.originAirport ?? '',
                  );

                  return newAirportsFilterCheckedItem.iata ===
                    searchItent?.originAirport
                    ? true
                    : airportData?.metropolitan_area_code ===
                        originAirportData?.airport_code;
                })
                .map(
                  (item, index, array) =>
                    item.name && (
                      <div
                        key={index}
                        id={`${index}-div-body-fiters-element-item-origim`}
                        className="div-body-fiters-element-item">
                        <CheckBox
                          isBlocked={
                            // getAirportData(item.iata)
                            //   ?.metropolitan_area_code === '' ||
                            array.length === 1
                          }
                          isChecked={item.isChecked}
                          checkedBackgroundColor="#00688D"
                          checkedIconColor="#ffffff"
                          onClick={() =>
                            changeAirportsFilter(item, !item.isChecked)
                          }
                        />

                        <span className="text-iata-fiters-element">{`${item.iata}\u00A0`}</span>

                        <span className="text-airports-fiters-element">
                          {item.name}
                        </span>
                      </div>
                    ),
                )}
            </div>
          </div>
        </div>

        {searchItent?.destinationAirport && (
          <div className="div-fiters-element">
            <div className="div-title-fiters-element">
              <Icon iconName="plane-landing" />
              <span className="text-title-fiters-element">{`${getAirportData(
                searchItent?.destinationAirport ?? '',
              )?.city_name.toUpperCase()}`}</span>
            </div>
            <div className="div-body-fiters-element-airports">
              <div className="div-airports-list-fiters-element">
                {airportsFilterList
                  .filter(newAirportsFilterCheckedItem => {
                    const airportData = getAirportData(
                      newAirportsFilterCheckedItem.iata,
                    );
                    const destinationAirportData = getAirportData(
                      searchItent?.destinationAirport ?? '',
                    );

                    return newAirportsFilterCheckedItem.iata ===
                      searchItent?.destinationAirport
                      ? true
                      : airportData?.metropolitan_area_code ===
                          destinationAirportData?.airport_code;
                  })
                  .map(
                    (item, index, array) =>
                      item.name && (
                        <div
                          key={index}
                          id={`${index}-div-body-fiters-element-item-origim`}
                          className="div-body-fiters-element-item">
                          <CheckBox
                            isBlocked={
                              // getAirportData(item.iata)
                              //   ?.metropolitan_area_code === '' ||
                              array.length === 1
                            }
                            isChecked={item.isChecked}
                            checkedBackgroundColor="#00688D"
                            checkedIconColor="#ffffff"
                            onClick={() =>
                              changeAirportsFilter(item, !item.isChecked)
                            }
                          />

                          <span className="text-iata-fiters-element">{`${item.iata}\u00A0`}</span>

                          <span className="text-airports-fiters-element">
                            {item.name}
                          </span>
                        </div>
                      ),
                  )}
              </div>
            </div>
          </div>
        )}

        <div className="div-dashed-line-fiters" />

        <div ref={refs.section4} className="div-fiters-element">
          <div className="div-title-fiters-element">
            <Icon iconName="up-right-arrow" width={22} />
            <span className="text-title-fiters-element">HORÁRIO DE IDA</span>
          </div>

          <div className="div-body-fiters-element-column">
            <div className="div-body-fiters-element-top">
              <span className="text-body-fiters-element-top-item">Entre</span>
              <span className="text-body-fiters-element-top-item-bold">{`\u00A0${minuteToClock(
                outboundTimeMin < minuteMax
                  ? outboundTimeMin
                  : outboundTimeMin - 1,
              )}\u00A0`}</span>
              <span className="text-body-fiters-element-top-item">e</span>
              <span className="text-body-fiters-element-top-item-bold">{`\u00A0${minuteToClock(
                outboundTimeMax < minuteMax
                  ? outboundTimeMax
                  : outboundTimeMax - 1,
              )}`}</span>
            </div>
            <div className="div-slider-filter">
              <RangeSlider
                className="slider-filter"
                min={0}
                max={minuteMax}
                step={30}
                value={[outboundTimeMin, outboundTimeMax]}
                defaultValue={[outboundTimeMinSaved, outboundTimeMaxSaved]}
                onInput={(values: any) => {
                  setOutboundTimeMin(values[0]);
                  setOutboundTimeMax(values[1]);
                }}
              />
            </div>
          </div>
        </div>

        <div className="div-fiters-element">
          <div className="div-title-fiters-element">
            <Icon iconName="down-left-arrow" width={22} />
            <span className="text-title-fiters-element">HORÁRIO DE VOLTA</span>
          </div>

          <div className="div-body-fiters-element-column">
            <div className="div-body-fiters-element-top">
              <span className="text-body-fiters-element-top-item">Entre</span>
              <span className="text-body-fiters-element-top-item-bold">{`\u00A0${minuteToClock(
                returnTimeMin < minuteMax ? returnTimeMin : returnTimeMin - 1,
              )}\u00A0`}</span>
              <span className="text-body-fiters-element-top-item">e</span>
              <span className="text-body-fiters-element-top-item-bold">{`\u00A0${minuteToClock(
                returnTimeMax < minuteMax ? returnTimeMax : returnTimeMax - 1,
              )}`}</span>
            </div>
            <div className="div-slider-filter">
              <RangeSlider
                className="slider-filter"
                min={0}
                max={minuteMax}
                step={30}
                value={[returnTimeMin, returnTimeMax]}
                defaultValue={[returnTimeMinSaved, returnTimeMaxSaved]}
                onInput={(values: any) => {
                  setReturnTimeMin(values[0]);
                  setReturnTimeMax(values[1]);
                }}
              />
            </div>
          </div>
        </div>

        <div className="div-dashed-line-fiters" />

        <div ref={refs.section5} className="div-fiters-element">
          <div className="div-title-fiters-element">
            <Icon iconName="airplane-wing" width={25} />
            <span className="text-title-fiters-element">COMPANHIAS AÉREAS</span>
          </div>

          <div className="div-body-fiters-element-airports">
            {airlinesFilterList.map(
              (item: AirlinesFilterProps, index: number) => (
                <div className="div-airports-list-fiters-element">
                  <div className="div-body-fiters-element-item">
                    <CheckBox
                      isChecked={item.isChecked}
                      checkedBackgroundColor="#00688D"
                      checkedIconColor="#ffffff"
                      onClick={() =>
                        changeAirlinesFilter(item, !item.isChecked)
                      }
                    />
                    <img
                      className="image-flight-filter"
                      src={`https://voopter.com.br/voopter-static-assets/airline-logos/${item.airlineCode}.png`}
                    />
                    <span className="text-airports-fiters-element">
                      {item.airlineName}
                    </span>
                  </div>
                </div>
              ),
            )}
          </div>
        </div>

        <div className="div-dashed-line-fiters" />

        <div ref={refs.section6} className="div-fiters-element">
          <div className="div-title-fiters-element">
            <Icon iconName="ticket" width={25} />
            <span className="text-title-fiters-element">VENDEDORES</span>
          </div>

          <div className="div-body-fiters-element-airports">
            <div className="div-airports-list-fiters-element">
              {agentsFilterList.map((item: AgentsFilterProps) => (
                <div className="div-body-fiters-element-item">
                  <CheckBox
                    isChecked={item.isChecked}
                    checkedBackgroundColor="#00688D"
                    checkedIconColor="#ffffff"
                    onClick={() => changeAgentsFilter(item, !item.isChecked)}
                  />

                  <img
                    className="image-flight-filter"
                    src={`${item.agentLogo}`}
                  />

                  <span className="text-airports-fiters-element">
                    {item.agentName}
                  </span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const showFilterModal = () => {
    setPriceMin(priceMinSaved);
    setPriceMax(priceMaxSaved);
    setAirportsFilterList(airportsFilter);
    setAirlinesFilterList(airlinesFilterListSaved);
    setAgentsFilterList(agentsFilterListSaved);
    handlerShowFilterModal(true);
  };

  return (
    <>
      {isFlightModalVisibile && flightCardItem && (
        <div className={'div-background-footer-modal'}>
          <FooterModal
            height={100}
            title="Onde comprar"
            iconName={'ticket'}
            onClose={() => {
              handlerFlightModalVisibile(false);
            }}>
            <div
              className="div-content-footer-flight-card-modal"
              ref={detailsCardModalRef}>
              <FlightCard
                flight={flightCardItem}
                currentPage={currentPage}
                isFlightDetailsStartedOpen
                isShowOnModel
                onClose={() => handlerFlightModalVisibile(false)}
                onAnchorSellers={() => {
                  if (detailsCardModalRef.current) {
                    detailsCardModalRef.current.scrollTop = 0;
                  }
                }}
              />
            </div>
          </FooterModal>
        </div>
      )}

      {isShowFilterModal && (
        <div className={'div-background-footer-modal'}>
          <FooterModal
            height={100}
            title="Filtros"
            iconName={'filters'}
            onClose={() => {
              handlerShowFilterModal(false);
            }}>
            <div className="div-content-footer-filters-modal-father">
              <div
                className="div-content-footer-filters-modal"
                ref={detailsCardModalRef}>
                {getFilterComponent()}
              </div>

              <div className="div-content-footer-filters-modal-bottom">
                <div className="div-content-footer-filters-modal-button-group">
                  <ButtonGroup
                    onClickLeftOption={() => clearFilter()}
                    onClickRightOption={() => applyFilter()}
                  />
                </div>
              </div>
            </div>
          </FooterModal>
        </div>
      )}

      <div
        ref={divRef}
        className={`div-mobile-flights-filter ${
          isShowMobileFilter ? 'show' : ''
        }`}
        onClick={e => handleClick(e)}>
        {/* {isShowMobileFilter && !hasZeroFlightsResults && (
          <FlightsFilter isMobileMode />
        )} */}
      </div>
      <div
        style={{
          display: 'flex',
          width: '100%',
          marginTop: 0,
          // backgroundColor: '#0F0',
          justifyContent: 'center',
        }}>
        {/* {!isShowBannerfilter && ( */}
      </div>

      <div
        className="div-banner-home-desktop-interface"
        style={{marginBottom: searchItent?.originAirport ? 30 : 0}}>
        <AdBanner
          adUnit={
            process.env.REACT_APP_IS_LOCAL
              ? 'local-gpt'
              : 'div-gpt-ad-1722356404777-0'
          }
          width={970}
          height={90}
          backgroundColor={
            process.env.REACT_APP_IS_LOCAL ? '#e90303' : 'transparent'
          }
        />
      </div>

      {/* SearchResultHeader */}
      {!isMobile && searchItent?.originAirport && (
        <div ref={resultAnchorRef} className="div-search-result-header">
          <div
            className="div-search-result-header-content"
            style={{
              // width: '70%',
              height: 90,
              // backgroundColor: '#004f98',
            }}>
            <div className="div-search-result-header-content-left">
              <div className="div-search-result-header-content-left-up-content">
                <div className={`div-fligts-filters-up`}>
                  <span className={`text-iata-fligts-filters-up`}>
                    {searchItent?.originAirport}
                  </span>

                  <FiArrowUpRight className="icon-departure-fligts-filters-up" />

                  {searchItent?.returnDate?.length ? (
                    <FiArrowDownLeft className="icon-return-fligts-filters-up" />
                  ) : null}

                  <span
                    className={`text-iata-fligts-filters-up`}
                    style={{marginRight: 10}}>
                    {searchItent?.destinationAirport}
                  </span>
                  {/* <div className={`div-profile-icon-fligts-filters-up`}>
                    <Icon
                      iconName="profile-new"
                      width={18}
                      iconColor="#4F4F4F"
                    />
                  </div> */}
                  <PassengersSelector
                    isRow
                    numberOfPassengers={{
                      adults: searchItent?.quantityOfAdults ?? 1,
                      children: searchItent?.quantityOfChildrens ?? 0,
                      babies: searchItent?.quantityOfBabies ?? 0,
                    }}
                  />
                </div>
              </div>
              <div className="div-search-result-header-content-left-bottom-content">
                <span className={`text-dates-fligts-filters-web`}>
                  {`${
                    searchItentReturnDatesObject.length >= 1
                      ? ''
                      : `${'Só\u00A0'}`
                  }${
                    searchItentDepartureDatesObject.length > 1
                      ? 'Idas'
                      : searchItentDepartureDatesObject.length === 1
                      ? 'Ida'
                      : 'Só idas'
                  }:${`\u00A0`}`}
                </span>
                {searchItentDepartureDatesObject.map((item, index) => (
                  <span
                    id={`${index}-text-departure-dates-fligts-filters-mid`}
                    className={`text-dates-fligts-filters-mid`}>
                    {`${item.day}/${(item.month + 1)
                      .toString()
                      .padStart(2, '0')}${
                      index + 1 < searchItentDepartureDatesObject.length
                        ? ',\u00A0'
                        : ''
                    }`}
                  </span>
                ))}

                <span
                  className={`text-dates-fligts-filters-web`}
                  style={{marginLeft: 20}}>
                  {`${
                    searchItentReturnDatesObject.length > 1
                      ? 'Voltas:'
                      : searchItentReturnDatesObject.length === 1
                      ? 'Volta:'
                      : ''
                  }\u00A0`}
                </span>
                {searchItentReturnDatesObject.map((item, index) => (
                  <span
                    id={`${index}-text-departure-dates-fligts-filters-mid`}
                    className={`text-dates-fligts-filters-mid`}>
                    {`${item.day}/${(item.month + 1)
                      .toString()
                      .padStart(2, '0')}${
                      index + 1 < searchItentReturnDatesObject.length
                        ? ',\u00A0'
                        : ''
                    }`}
                  </span>
                ))}
              </div>
            </div>
            <div className="div-search-result-header-content-right">
              {percentProgess < 100 &&
                !hasZeroFlightsResults &&
                !isSearchFinished && (
                  <>
                    <ProgressBar curretnPogress={Math.round(progress)} />
                  </>
                )}

              {isSearchFinished && !hasZeroFlightsResults && (
                <div className="div-result-message">
                  <span className="text-result-message-top">
                    {`Comparação de passagens aéreas finalizada!`}
                  </span>

                  <div className="animation-result-message">
                    <Icon iconName="check" iconColor="#FFFFFF" width={60} />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      <div className="div-body-content">
        {(percentProgess === 100 && !hasZeroFlightsResults) ||
        isSearchFinished ? (
          // ? !hasZeroFlightsResults && (
          <div className="div-left-body-content">
            {!hasZeroFlightsResults && getFilterComponent()}
          </div>
        ) : (
          searchItent?.originAirport &&
          !hasZeroFlightsResults && (
            <div className="div-left-body-content">
              <div className="div-skeleton-filter">
                <div
                  className="div-skeleton-filter-item"
                  style={{marginTop: 45}}>
                  <Skeleton width={10} height={25} borderRadius={100} />
                  <Skeleton
                    width={60}
                    height={25}
                    borderRadius={100}
                    marginLeft={10}
                  />
                </div>
                {Array.from({length: 4}).map((_, index) => (
                  <div
                    key={index}
                    className="div-skeleton-filter-item"
                    style={{marginTop: 25, paddingLeft: 15}}>
                    <Skeleton width={9} height={25} borderRadius={5} />
                    <Skeleton
                      width={30 + 5 * index}
                      height={25}
                      borderRadius={100}
                      marginLeft={10}
                    />
                  </div>
                ))}

                <div
                  className="div-skeleton-filter-item"
                  style={{marginTop: 45}}>
                  <Skeleton width={10} height={25} borderRadius={100} />
                  <Skeleton
                    width={60}
                    height={25}
                    borderRadius={100}
                    marginLeft={10}
                  />
                </div>

                <div
                  className="div-skeleton-filter-item"
                  style={{marginTop: 20}}>
                  <Skeleton
                    width={30}
                    height={15}
                    borderRadius={100}
                    marginLeft={10}
                  />

                  <Skeleton
                    width={50}
                    height={15}
                    borderRadius={100}
                    marginLeft={10}
                  />
                </div>

                <div
                  className="div-skeleton-filter-item"
                  style={{marginTop: 15}}>
                  <Skeleton
                    width={10}
                    height={30}
                    borderRadius={100}
                    marginLeft={10}
                  />

                  <Skeleton
                    width={70}
                    height={10}
                    borderRadius={0}
                    marginLeft={0}
                  />
                  <Skeleton
                    width={10}
                    height={30}
                    borderRadius={100}
                    marginLeft={0}
                  />
                </div>

                <div
                  className="div-skeleton-filter-item"
                  style={{marginTop: 35}}>
                  <Skeleton width={10} height={25} borderRadius={100} />
                  <Skeleton
                    width={60}
                    height={25}
                    borderRadius={100}
                    marginLeft={10}
                  />
                </div>
                {Array.from({length: 4}).map((_, index) => (
                  <div
                    key={index}
                    className="div-skeleton-filter-item"
                    style={{marginTop: 25, paddingLeft: 15}}>
                    <Skeleton width={9} height={25} borderRadius={5} />
                    <Skeleton
                      width={30 + 5 * index}
                      height={25}
                      borderRadius={100}
                      marginLeft={10}
                    />
                  </div>
                ))}
              </div>
            </div>
          )
        )}

        <div className="div-mid-body-content">
          {hasZeroFlightsResults && (
            <div className="div-no-filtered-flights">
              <Animation
                isShow={true}
                animationData={noFilterdFlights}
                width={window.innerWidth >= 500 ? 500 : 300}
                height={300}
              />
              <span className="text-no-filtered-flights">
                {'Nenhum resultado de voos para a pesquisa.'}
              </span>

              <div className="div-button-try-again-search">
                <Button
                  onClick={() => location.reload()}
                  title="TENTAR NOVAMENTE"
                />
              </div>
            </div>
          )}

          {!isMobile && inlineData && (
            <div className="div-mid-body-inline">
              <InlineBanner inlineData={inlineData} />
            </div>
          )}

          <div
            ref={resultAnchorMobileRef}
            className="div-fligts-filters-anchor"
          />
          {searchItent?.departureDate && !hasZeroFlightsResults && isMobile && (
            <div className={`div-fligts-filters`}>
              <div className={`div-fligts-filters-up`}>
                <span className={`text-iata-fligts-filters-up`}>
                  {searchItent?.originAirport}
                </span>

                <FiArrowUpRight className="icon-departure-fligts-filters-up" />
                {searchItentReturnDatesObject.length > 0 && (
                  <FiArrowDownLeft className="icon-return-fligts-filters-up" />
                )}

                <span className={`text-iata-fligts-filters-up`}>
                  {searchItent?.destinationAirport}
                </span>
                <div className={`div-profile-icon-fligts-filters-up`}>
                  <Icon iconName="profile-new" width={18} iconColor="#4F4F4F" />
                </div>

                {searchItent && (
                  <span className={`text-passengers-fligts-filters-up`}>
                    {searchItent.quantityOfAdults +
                      searchItent?.quantityOfChildrens +
                      searchItent?.quantityOfBabies}
                  </span>
                )}

                <div className={`div-edit-icon-fligts-filters-up`}>
                  <Icon
                    iconName="edit"
                    width={25}
                    iconColor="#00688D"
                    onClick={() => {
                      window.scrollTo({
                        top: 0,
                        // behavior: 'smooth',
                      });
                    }}
                  />
                </div>
              </div>

              <div className={`div-fligts-filters-mid`}>
                <div className="div-dates-fligts-filters-mid">
                  <FiArrowUpRight className="icon-departure-fligts-filters-up" />
                  {searchItentDepartureDatesObject.map((item, index) => (
                    <span
                      id={`${index}-text-departure-dates-fligts-filters-mid`}
                      className={`text-dates-fligts-filters-mid`}>
                      {`${item.day}/${(item.month + 1)
                        .toString()
                        .padStart(2, '0')}${
                        index + 1 < searchItentDepartureDatesObject.length
                          ? ',\u00A0'
                          : ''
                      }`}
                    </span>
                  ))}
                </div>

                {searchItentReturnDatesObject.length > 0 && (
                  <div className="div-dates-fligts-filters-mid">
                    <FiArrowDownLeft className="icon-return-fligts-filters-up" />
                    {searchItentReturnDatesObject.map((item, index) => (
                      <span
                        id={`${index}-text-return-dates-fligts-filters-mid`}
                        className={`text-dates-fligts-filters-mid`}>
                        {`${item.day}/${(item.month + 1)
                          .toString()
                          .padStart(2, '0')}${
                          index + 1 < searchItentReturnDatesObject.length
                            ? ',\u00A0'
                            : ''
                        }`}
                      </span>
                    ))}
                  </div>
                )}
              </div>

              <div className="div-fligts-filters-bottom">
                <SelectButton
                  iconName="filters"
                  label={`${
                    countFiltersApplied > 0
                      ? countFiltersApplied > 1
                        ? `${countFiltersApplied} filtros ativos`
                        : `${countFiltersApplied} filtro ativo`
                      : 'filtros'
                  }`}
                  isChecked={countFiltersApplied > 0}
                  onClick={() => {
                    showFilterModal();
                    // scrollToElement('section1');
                  }}
                />
                <SelectButton
                  withCheckBox
                  label="Só diretos"
                  isChecked={isOnlyDirectStopsChecked}
                  onClick={() => {
                    applyOnlyDirectStops(!isOnlyDirectStopsChecked);
                  }}
                />
                <SelectButton
                  label="Preço"
                  sizeSelectButton={80}
                  isChecked={isPriceFilterApplied}
                  onClick={() => {
                    showFilterModal();
                    scrollToElement('section2');
                  }}
                />

                <SelectButton
                  label="Aeroportos"
                  sizeSelectButton={80}
                  isChecked={isAirportFilterApplied}
                  onClick={() => {
                    showFilterModal();
                    scrollToElement('section3');
                  }}
                />

                <SelectButton
                  label="Horários"
                  sizeSelectButton={80}
                  isChecked={isDepartureTimeFilterApplied}
                  onClick={() => {
                    showFilterModal();
                    scrollToElement('section4');
                  }}
                />

                {/* <SelectButton
                  label="Duração"
                  sizeSelectButton={80}
                  isChecked={isChecked}
                  onClick={() => showFilterModal()}
                /> */}

                <SelectButton
                  label="Cia Aérea"
                  sizeSelectButton={80}
                  isChecked={isAirlineFilterApplied}
                  onClick={() => {
                    showFilterModal();
                    scrollToElement('section5');
                  }}
                />

                <SelectButton
                  label="Vendedor"
                  sizeSelectButton={80}
                  isChecked={isAgentFilterApplied}
                  onClick={() => {
                    showFilterModal();
                    scrollToElement('section6');
                  }}
                />
              </div>
            </div>
          )}

          {isMobile && !hasZeroFlightsResults && (
            <>
              <div className="div-progress-bar-result-message ">
                {searchItent?.originAirport &&
                  percentProgess < 100 &&
                  !hasZeroFlightsResults &&
                  !isSearchFinished && (
                    <>
                      <ProgressBar curretnPogress={Math.round(progress)} />
                    </>
                  )}

                {isSearchFinished && (
                  <div className="div-result-message">
                    <span className="text-result-message-top">
                      {`Comparação de passagens aéreas finalizada!`}
                    </span>

                    {/* <span className="text-result-message-bottom">
                    {`${flightsFilteredList?.length} tarifas encontradas.`}
                  </span> */}

                    <div className="animation-result-message">
                      <Icon iconName="check" iconColor="#FFFFFF" width={30} />
                    </div>
                  </div>
                )}
              </div>
              {inlineData && (
                <div className="div-mid-body-inline">
                  <InlineBanner inlineData={inlineData} />
                </div>
              )}
            </>
          )}

          {isMobile &&
            sksFlights <= 0 &&
            searchItent?.departureDate &&
            !hasZeroFlightsResults &&
            Array.from({length: 4}).map((_, index) => (
              <FlightCardMobileSkeleton />
            ))}

          {!isMobile &&
            sksFlights?.length === 0 &&
            searchItent?.departureDate &&
            !hasZeroFlightsResults && (
              <div className="div-pagination-container">
                <div className="div-pagination-container-cards">
                  {Array.from({length: 5}).map((_, index) => (
                    <div className="div-flight-card-skeleton">
                      <div className="div-flight-card-skeleton-left">
                        {Array.from({length: 2}).map((_, index) => (
                          <div className="div-flight-card-skeleton-left-item">
                            <Skeleton
                              width={20}
                              height={25}
                              borderRadius={100}
                              marginLeft={10}
                            />

                            <Skeleton
                              width={10}
                              height={25}
                              borderRadius={100}
                              marginLeft={30}
                            />

                            <Skeleton
                              width={10}
                              height={40}
                              borderRadius={10}
                              marginLeft={40}
                            />

                            <Skeleton
                              width={20}
                              height={35}
                              borderRadius={100}
                              marginLeft={10}
                            />
                            <Skeleton
                              width={10}
                              height={40}
                              borderRadius={10}
                              marginLeft={10}
                            />
                          </div>
                        ))}
                      </div>

                      <div className="div-flight-card-skeleton-right">
                        <Skeleton
                          width={90}
                          height={90}
                          borderRadius={10}
                          marginLeft={0}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}

          {flightsFilteredList?.length > 0 && !hasZeroFlightsResults ? (
            <div className="div-pagination-container">
              <div className="div-pagination-container-cards">
                {flightsFilteredList
                  .slice(offset, offset + itemsPerPage)
                  .map((item: flightDataProps, index: any) => (
                    <FlightCard
                      key={`flight-card-${index}`}
                      flight={item}
                      currentPage={currentPage}
                      onClick={() => {
                        setFlightCardItem(item);
                        handlerFlightModalVisibile(true);
                        handleGTMFlightCardEvent(
                          item,
                          index,
                          currentPage,
                          getUniqueAirlines(item),
                          parseInt(item?.pricingOptions[0]?.price),
                        );
                      }}
                      gtmFlightCardEvent={() => {
                        handleGTMFlightCardEvent(
                          item,
                          index,
                          currentPage,
                          getUniqueAirlines(item),
                          parseInt(item?.pricingOptions[0]?.price),
                        );
                      }}
                    />
                  ))}
              </div>

              <ReactPaginate
                previousLabel={''}
                nextLabel={''}
                breakLabel={'...'}
                pageCount={Math.ceil(
                  flightsFilteredList?.length / itemsPerPage,
                )}
                marginPagesDisplayed={2}
                onPageChange={handlePageClick}
                containerClassName={'paginationContainer'}
                activeClassName={'active'}
                pageLinkClassName={'page-link'}
              />
            </div>
          ) : (
            sksFlights?.length > 0 &&
            !hasZeroFlightsResults && (
              <div className="div-no-filtered-flights">
                <Animation
                  isShow={true}
                  animationData={noFilterdFlights}
                  width={window.innerWidth >= 500 ? 500 : 300}
                  height={300}
                />
                <span className="text-no-filtered-flights">
                  {'Nenhum resultado de voos para o filtro.'}
                </span>
              </div>
            )
          )}
        </div>

        <div className="div-right-content">
          {/* {sideSearchData?.length ? (
            <SideSearchBanner sideSearchData={sideSearchData} />
          ) : null} */}

          {isSearchUrl(window.location.href) && (
            <>
              <div className="div-banner-home-desktop-contents">
                <AdBanner
                  adUnit={
                    process.env.REACT_APP_IS_LOCAL
                      ? 'local-gpt'
                      : 'div-gpt-ad-1722357160908-0'
                  }
                  width={300}
                  height={250}
                  backgroundColor={
                    process.env.REACT_APP_IS_LOCAL ? '#e90303' : 'transparent'
                  }
                />
              </div>

              <div className="div-banner-home-desktop-contents">
                <AdBanner
                  adUnit={
                    process.env.REACT_APP_IS_LOCAL
                      ? 'local-gpt'
                      : 'div-gpt-ad-1722365640523-0'
                  }
                  width={300}
                  height={250}
                  backgroundColor={
                    process.env.REACT_APP_IS_LOCAL ? '#e90303' : 'transparent'
                  }
                />
              </div>
            </>
          )}
        </div>
      </div>

      <div className="div-blog-posts-content">
        <div className="div-ad-banner-search">
          {/* <AdBanner
            adUnit="div-gpt-ad-1705080887387-0"
            width={728}
            height={90}
            backgroundColor={
              process.env.REACT_APP_IS_LOCAL ? '#e90303' : 'transparent'
            }
          /> */}
        </div>
        <div className="div-right-content-mobile">
          {/* {sideSearchData?.length ? (
            <SideSearchBanner sideSearchData={sideSearchData} />
          ) : null} */}
        </div>
        <span className="title-text-offers-tips">
          Viaje mais pelo Brasil e pelo mundo!
        </span>
        {/* <div className="card-grid">
          {offersTipsPosts.map(
            (itemOfferTip: offerTipPostProps, index: number) => (
              <OfferTipCard
                onClick={() => (window.location.href = itemOfferTip.permalink)}
                key={`${index}-item-offer-tip`}
                title={itemOfferTip.title}
                description="test description"
                image={getImage(itemOfferTip)}
                postedAt={getCreatedAt(itemOfferTip)}
                iconName={''}
              />
            ),
          )}
        </div> */}
        <div className="intro-container">
          <div className="intro-title">
            Como encontrar passagens aéreas baratas com o Voopter
          </div>
          <div className="intro-section">
            <div
              className="intro-section-components"
              onClick={() => toggleIntro(1)}>
              <div className="intro-section-title">
                <h1>Encontre Passagens Aéreas Baratas com o Voopter</h1>
              </div>
              <div className="intro-section-arrow">
                <span>
                  <span
                    style={{
                      lineHeight: '1rem',
                      display: 'inline-block',
                      marginTop: '0.25rem',
                      verticalAlign: 'top',
                    }}>
                    {introToggles[1] && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        aria-hidden="true"
                        width="1rem"
                        height="1rem">
                        <path d="M19.113 8.095a1.496 1.496 0 0 1 0 2.008l-6.397 5.948a1 1 0 0 1-1.358.003l-6.532-6.01a1.427 1.427 0 0 1 .138-1.949 1.57 1.57 0 0 1 1.997-.103l5.078 4.638 4.97-4.535a1.72 1.72 0 0 1 2.104 0"></path>
                      </svg>
                    )}
                    {!introToggles[1] && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        aria-hidden="true"
                        width="1rem"
                        height="1rem"
                        style={{transform: 'rotate(180deg)'}}>
                        <path d="M19.113 8.095a1.496 1.496 0 0 1 0 2.008l-6.397 5.948a1 1 0 0 1-1.358.003l-6.532-6.01a1.427 1.427 0 0 1 .138-1.949 1.57 1.57 0 0 1 1.997-.103l5.078 4.638 4.97-4.535a1.72 1.72 0 0 1 2.104 0"></path>
                      </svg>
                    )}
                  </span>
                </span>
              </div>
            </div>
            <div
              className="intro-section-content"
              style={{display: !introToggles[1] ? 'block' : 'none'}}>
              <p>
                Buscando ofertas para suas viagens? No Voopter, você compara
                preços de passagens aéreas e encontra promoções relâmpago para
                destinos no Brasil e no mundo.
              </p>
              <p>
                {' '}
                O Voopter reúne opções de diversas{' '}
                <b>
                  companhias aéreas, agências de viagem e sites de milhas
                </b>{' '}
                em uma única pesquisa multidatas, ajudando você a viajar com o
                melhor custo-benefício.
              </p>
              <p>
                {' '}
                Planeje seus voos com economia e segurança, encontrando preços
                que cabem no seu orçamento.
              </p>
            </div>
          </div>
          <div className="intro-section">
            <div
              className="intro-section-components"
              onClick={() => toggleIntro(2)}>
              <div className="intro-section-title">
                <h2>Como Comparar Preços e Criar Alertas no Voopter</h2>
              </div>
              <div className="intro-section-arrow">
                <span>
                  <span
                    style={{
                      lineHeight: '1rem',
                      display: 'inline-block',
                      marginTop: '0.25rem',
                      verticalAlign: 'top',
                    }}>
                    {introToggles[2] && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        aria-hidden="true"
                        width="1rem"
                        height="1rem">
                        <path d="M19.113 8.095a1.496 1.496 0 0 1 0 2.008l-6.397 5.948a1 1 0 0 1-1.358.003l-6.532-6.01a1.427 1.427 0 0 1 .138-1.949 1.57 1.57 0 0 1 1.997-.103l5.078 4.638 4.97-4.535a1.72 1.72 0 0 1 2.104 0"></path>
                      </svg>
                    )}
                    {!introToggles[2] && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        aria-hidden="true"
                        width="1rem"
                        height="1rem"
                        style={{transform: 'rotate(180deg)'}}>
                        <path d="M19.113 8.095a1.496 1.496 0 0 1 0 2.008l-6.397 5.948a1 1 0 0 1-1.358.003l-6.532-6.01a1.427 1.427 0 0 1 .138-1.949 1.57 1.57 0 0 1 1.997-.103l5.078 4.638 4.97-4.535a1.72 1.72 0 0 1 2.104 0"></path>
                      </svg>
                    )}
                  </span>
                </span>
              </div>
            </div>
            <div
              className="intro-section-content"
              style={{display: !introToggles[2] ? 'block' : 'none'}}>
              <p>
                Contando com um <b>exclusivo calendário de múltiplas datas</b>,
                o Voopter facilita a comparação de preços de várias companhias
                simultaneamente, garantindo a opção mais econômica para sua
                viagem.
              </p>
              <p>
                {' '}
                Crie{' '}
                <a
                  href="https://www.voopter.com.br/alertas"
                  target="_blank"
                  rel="noreferrer"
                  style={{color: 'inherit'}}>
                  alertas de preços
                </a>{' '}
                e receba emails sempre que melhores tarifas forem encontradas
                para seus destinos preferidos, nas datas e preços desejados.
                Assim, você não perde nenhuma promoção e economiza ainda mais.
              </p>
              <p>
                {' '}
                Confie no Voopter para encontrar voos pelos melhores preços e
                finalizar sua compra com segurança nos sites de nossos
                parceiros.
              </p>
            </div>
          </div>
          <div className="intro-section">
            <div
              className="intro-section-components"
              onClick={() => toggleIntro(3)}>
              <div className="intro-section-title">
                <h3>Descubra Achados e Promoções Relâmpago</h3>
              </div>
              <div className="intro-section-arrow">
                <span>
                  <span
                    style={{
                      lineHeight: '1rem',
                      display: 'inline-block',
                      marginTop: '0.25rem',
                      verticalAlign: 'top',
                    }}>
                    {introToggles[3] && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        aria-hidden="true"
                        width="1rem"
                        height="1rem">
                        <path d="M19.113 8.095a1.496 1.496 0 0 1 0 2.008l-6.397 5.948a1 1 0 0 1-1.358.003l-6.532-6.01a1.427 1.427 0 0 1 .138-1.949 1.57 1.57 0 0 1 1.997-.103l5.078 4.638 4.97-4.535a1.72 1.72 0 0 1 2.104 0"></path>
                      </svg>
                    )}
                    {!introToggles[3] && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        aria-hidden="true"
                        width="1rem"
                        height="1rem"
                        style={{transform: 'rotate(180deg)'}}>
                        <path d="M19.113 8.095a1.496 1.496 0 0 1 0 2.008l-6.397 5.948a1 1 0 0 1-1.358.003l-6.532-6.01a1.427 1.427 0 0 1 .138-1.949 1.57 1.57 0 0 1 1.997-.103l5.078 4.638 4.97-4.535a1.72 1.72 0 0 1 2.104 0"></path>
                      </svg>
                    )}
                  </span>
                </span>
              </div>
            </div>
            <div
              className="intro-section-content"
              style={{display: !introToggles[3] ? 'block' : 'none'}}>
              <p>
                Você quer as melhores ofertas para sua próxima viagem? Com o
                Voopter, você compara{' '}
                <b>preços de passagens de diversos sites</b> e descobre
                promoções relâmpago para destinos nacionais e internacionais,
                além de achados incríveis do{' '}
                <a
                  href="https://club.voopter.com.br?utm_source=voopter_metasearch"
                  target="_blank"
                  rel="noreferrer"
                  style={{color: 'inherit'}}>
                  Voopter Club
                </a>{' '}
                .
              </p>
              <p>
                {' '}
                Economize e viaje com segurança, encontrando as passagens mais
                acessíveis em uma única ferramenta.
              </p>
            </div>
          </div>
        </div>

        <div className="div-voopter-grid">
          <div className="voopter-grid">
            {/* Uncomment to show about Voopter apps */}

            {/* <div className="div-phones-voopter-grid">
              <img
                className="img-phones-voopter-grid"
                src={mobileAppsImage}
                alt="Encontre passagens aéreas baratas no aplicativo do Voopter"
              />
            </div>

            <div className="div-apps-voopter-grid">
              <span className="text-item-voopter-grid">{'VOOPTER MOBILE'}</span>
              <span className="text-apps-voopter-grid">
                {'Encontre os melhores preços de qualquer lugar'}
              </span>

              <div className="div-store-apps">
                <>
                  <div
                    className="div-store-apps-ios"
                    onClick={() =>
                      window.open(
                        'https://apps.apple.com/br/app/voopter-pre%C3%A7os-de-voos/id849215105',
                        '_blank',
                      )
                    }>
                    <div className="div-icon-ios">
                      <AiFillApple />
                    </div>
                  </div>
                  <span className="text-phone-platform ">IPHONE</span>
                </>

                <>
                  <div
                    className="div-store-apps-android"
                    onClick={() =>
                      window.open(
                        'https://play.google.com/store/apps/details?id=com.voopter&hl=pt_BR',
                        '_blank',
                      )
                    }>
                    <div className="div-icon-ios">
                      <AiFillAndroid />
                    </div>
                  </div>
                  <span className="text-phone-platform ">ANDROID</span>
                </>
              </div>
            </div> */}

            <div className="div-video-voopter-grid">
              <div className="div-banner-home-mobile-content">
                <AdBanner
                  adUnit={
                    process.env.REACT_APP_IS_LOCAL
                      ? 'local-gpt'
                      : 'div-gpt-ad-1720783704225-0'
                  }
                  width={300}
                  height={250}
                  backgroundColor={
                    process.env.REACT_APP_IS_LOCAL ? '#e90303' : 'transparent'
                  }
                />
              </div>

              {/* <span className="text-item-voopter-grid">
                {"ASSIM FUNCIONA O VOOPTER"}
              </span>
              <img
                className="img-video-voopter-grid"
                onClick={() =>
                  window.open(
                    "https://www.youtube.com/watch?v=I-Mwzs-r46A",
                    "_blank"
                  )
                }
                src={howToUseImage}
                alt="Encontre passagens aéreas baratas no aplicativo do Voopter"
              /> */}
            </div>

            <div className="div-banner-home-mobile-footer">
              <AdBanner
                adUnit={
                  process.env.REACT_APP_IS_LOCAL
                    ? 'local-gpt'
                    : 'div-gpt-ad-1720783988182-0'
                }
                width={320}
                height={50}
                backgroundColor={
                  process.env.REACT_APP_IS_LOCAL ? '#e90303' : 'transparent'
                }
              />
            </div>
          </div>
        </div>
        <div className="div-banner-home-desktop-footer">
          <AdBanner
            adUnit={
              process.env.REACT_APP_IS_LOCAL
                ? 'local-gpt'
                : 'div-gpt-ad-1722356623528-0'
            }
            width={970}
            height={90}
            backgroundColor={
              process.env.REACT_APP_IS_LOCAL ? '#e90303' : 'transparent'
            }
          />
        </div>
      </div>
      <div data-getsitecontrol-inline="385770"></div>
    </>
  );
}

export default BodyContent;
