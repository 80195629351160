/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
// eslint-disable-next-line react-hooks/exhaustive-deps
import React, {useEffect, useRef, useState} from 'react';
import 'react-modern-calendar-datepicker/lib/DatePicker.css';
import './CalendarPicker.css';
import {Calendar, utils} from 'react-modern-calendar-datepicker';
import {OwnProps, CalendarDateProps} from './types';
import {myCustomLocale} from './const';
import {findMinAndMaxDate, getCurrentDate} from './functions';
import {useSelector} from 'react-redux';
import {getIsOneWayTrip} from '../../../store/flights';
import Button from '../../Buttons/Button';
import Icon from '../../Icon';
import {FiArrowDownLeft, FiArrowUpLeft, FiArrowUpRight} from 'react-icons/fi';
import {padToTwoDigits} from '../../../core/helpers/DateTime';
// import '../../../../src/index.css';

function CalendarPicker({
  onClose,
  onConfirm,
  onClickOut,
  selectedDates,
  onSelectDates,
  maxLimitSelected,
  isVisible,
  departureSelectedDates,
  returnSelectedDates,
  currentDatesValues,
  isDepartureCaledar,
  minDate,
  maxDate,
  isMoboile,
}: OwnProps) {
  const [currentDates, setCurrentDates] = useState<any>([]);
  const [selectedDays, setSelectedDays] = useState<CalendarDateProps[]>([]);
  const [currentDepartureDates, setDepartureDates] = useState<any>([]);
  const [isVisibleCalendar, handlerVisibleCalendar] = useState<boolean>(false);
  const [isDepartureCalendarState, handlerDepartureCalendarState] =
    useState<any>([]);
  const [maxLimitDates, setMaxLimitDates] = useState<CalendarDateProps | null>(
    null,
  );
  const [minLimitDates, setMinLimitDates] = useState<CalendarDateProps | null>(
    null,
  );

  const [month, setMonth] = useState<number>(new Date().getMonth());
  const [year, setYear] = useState<number>(new Date().getFullYear());

  const isOneWayTrip = useSelector(getIsOneWayTrip);

  const calendarRef = useRef<any>(null);

  useEffect(() => {
    isOneWayTrip && deleteAllDates();
  }, [isOneWayTrip]);

  useEffect(() => {
    handlerVisibleCalendar(isVisible);
  }, [isVisible]);

  useEffect(() => {
    setCurrentDates(currentDatesValues);
    maxDate && setMaxLimitDates(maxDate);
    minDate && setMinLimitDates(minDate);
  }, [currentDatesValues]);

  useEffect(() => {
    const newselectedDays = departureSelectedDates?.map(
      (item: CalendarDateProps) => ({
        ...item,
        className: !isDepartureCaledar
          ? 'departure-dates-selected'
          : 'retur-dates-selected',
      }),
    );
    setDepartureDates(newselectedDays);
  }, [departureSelectedDates]);

  useEffect(() => {
    handlerDepartureCalendarState(isDepartureCaledar);
    // setSelectedDays([]);
  }, [isDepartureCaledar]);

  useEffect(() => {
    selectedDates && selectedDates(selectedDays);
  }, [selectedDays]);

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (calendarRef.current && !calendarRef.current.contains(event.target)) {
        onClickOut && onClickOut();
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [calendarRef, isVisible]);

  const deleteDate = (dateItem: CalendarDateProps) => {
    const targetSelectDays = isDepartureCaledar
      ? departureSelectedDates
      : returnSelectedDates;
    const newSelectedDays = targetSelectDays?.filter(
      (item: CalendarDateProps) =>
        item.day !== dateItem.day ||
        item.month !== dateItem.month ||
        item.year !== dateItem.year,
    );

    newSelectedDays && setSelectedDays(newSelectedDays);
    // setCurrentDates(newSelectedDays);
  };

  const deleteAllDates = () => {
    // setSelectedDays([]);
    // setCurrentDates([]);
  };

  const daysOfWeek = ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'];
  const firstDayOfMonth = new Date(year, month, 1).getDay();
  const daysInMonth = new Date(year, month + 1, 0).getDate();

  const isRenderBackButton = () => {
    const currentDate = new Date();

    const isRender =
      year > currentDate.getFullYear()
        ? true
        : year >= currentDate.getFullYear() && month > currentDate.getMonth();

    return isRender;
  };

  function isDateGreaterOrEqual(date1: string, date2: string) {
    const [year1, month1, day1] = date1.split('-').map(Number);
    const [year2, month2, day2] = date2.split('-').map(Number);

    if (year1 > year2) return true;
    if (year1 < year2) return false;
    if (month1 > month2) return true;
    if (month1 < month2) return false;
    return day1 >= day2;
  }

  const isValidDay = (itemDay: number) => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();
    const currentDay = currentDate.getDate();

    const formattedCurrentDate = `${currentYear}-${
      currentMonth + 1
    }-${currentDay}`;

    const earliestDepartureDate =
      departureSelectedDates &&
      departureSelectedDates.length > 0 &&
      !isDepartureCaledar
        ? `${departureSelectedDates[0].year}-${padToTwoDigits(
            departureSelectedDates[0].month + 1,
          )}-${padToTwoDigits(departureSelectedDates[0].day)}`
        : formattedCurrentDate;

    const dateObject = `${year}-${padToTwoDigits(month + 1)}-${padToTwoDigits(
      itemDay,
    )}`;

    const validDate = isDateGreaterOrEqual(dateObject, earliestDepartureDate);
    const isRender = isDateGreaterOrEqual(dateObject, formattedCurrentDate);

    return isRender && validDate;
  };

  const renderMonthControl = () => {
    return (
      <div className="calendar-month-control">
        <div style={{visibility: isRenderBackButton() ? 'visible' : 'hidden'}}>
          <Icon
            iconName="left-arrow"
            width={25}
            iconColor="#666666"
            onClick={() => {
              if (month !== 0) {
                setMonth(month - 1);
              } else {
                setYear(year - 1);
                setMonth(11);
              }
            }}
          />
        </div>

        <span className="text-month-year">
          {`${myCustomLocale.months[month]} ${year}`}
        </span>

        <div className="div-calendar-control">
          <Icon
            iconName="right-arrow"
            width={25}
            iconColor="#666666"
            onClick={() => {
              if (month !== 11) {
                setMonth(month + 1);
              } else {
                setYear(year + 1);
                setMonth(0);
              }
            }}
          />
        </div>
      </div>
    );
  };

  const renderDaysOfWeek = () => {
    return daysOfWeek.map((day, index) => (
      <div key={index} className="calendar-day-of-week">
        {day}
      </div>
    ));
  };

  const onSelectDate = (date: CalendarDateProps) => {
    const targetSelectDays = isDepartureCaledar
      ? departureSelectedDates
      : returnSelectedDates;

    const earliestDepartureDate =
      departureSelectedDates &&
      departureSelectedDates?.length > 0 &&
      !isDepartureCaledar
        ? new Date(
            `${departureSelectedDates[0].year}-${padToTwoDigits(
              departureSelectedDates[0].month + 1,
            )}-${padToTwoDigits(departureSelectedDates[0].day)}`,
          )
        : new Date();

    const dateObject = new Date(
      `${date.year}-${padToTwoDigits(date.month + 1)}-${padToTwoDigits(
        date.day,
      )}`,
    );

    const year1 = dateObject.getFullYear();
    const month1 = dateObject.getMonth();
    const day1 = dateObject.getDate();

    const year2 = earliestDepartureDate.getFullYear();
    const month2 = earliestDepartureDate.getMonth();
    const day2 = earliestDepartureDate.getDate();

    // alert(`${day1}-${month1}-${year1}`);
    // alert(`${day2}-${month2}-${year2}`);

    if (
      year1 > year2 ||
      departureSelectedDates?.length === 0 ||
      isDepartureCaledar ||
      (year1 === year2 && month1 > month2) ||
      (year1 === year2 && month1 >= month2 && day1 >= day2)
    ) {
      targetSelectDays?.some(
        item =>
          item.day === date.day &&
          item.month === date.month &&
          item.year === date.year,
      )
        ? deleteDate(date)
        : targetSelectDays &&
          targetSelectDays.length < 4 &&
          setSelectedDays([...targetSelectDays, date]);
    }

    // !isDepartureCaledar && alert(earliestDepartureDate);
  };

  const getStyleDay = (day: number): string => {
    const isFindDepartureDate = departureSelectedDates?.some(
      item => item.day === day && item.month === month && item.year === year,
    );

    const isFindReturnDate = returnSelectedDates?.some(
      item => item.day === day && item.month === month && item.year === year,
    );

    if (isFindDepartureDate && isFindReturnDate) {
      return 'round-trip-calendar-day';
    } else if (isFindDepartureDate) {
      return isDepartureCaledar
        ? 'departure-calendar-day'
        : 'selected-calendar-day';
    } else if (isFindReturnDate) {
      return !isDepartureCaledar
        ? 'return-calendar-day'
        : 'selected-calendar-day';
    } else {
      return 'calendar-day';
    }
  };

  const renderDaysInMonth = () => {
    const days = [];
    for (let i = 0; i < firstDayOfMonth; i++) {
      days.push(<div key={`empty-${i}`} className="calendar-day-empty"></div>);
    }

    for (let i = 1; i <= daysInMonth; i++) {
      const styleDay = getStyleDay(i);
      days.push(
        <div
          key={i}
          className={isValidDay(i) ? styleDay : 'desable-calendar-day'}
          onClick={() =>
            isValidDay(i) && onSelectDate({day: i, month: month, year: year})
          }>
          {((!isDepartureCaledar && styleDay === 'selected-calendar-day') ||
            styleDay === 'round-trip-calendar-day' ||
            styleDay === 'departure-calendar-day') && (
            <div className="arrow-departure-calendar-day">
              <FiArrowUpRight />
            </div>
          )}

          {((isDepartureCaledar && styleDay === 'selected-calendar-day') ||
            styleDay === 'round-trip-calendar-day' ||
            styleDay === 'return-calendar-day') && (
            <div className="arrow-return-calendar-day">
              <FiArrowDownLeft />
            </div>
          )}
          <span className="text-calendar-day">{i}</span>
        </div>,
      );
    }
    return days;
  };

  return (
    <div ref={calendarRef} className="div-container-calendar">
      {!isMoboile && (
        <div className="calendar-header-web">
          <div className="calendar-header-web-left">
            <span className="title-calendar-header-web-left">
              {isDepartureCaledar ? 'Ida' : 'Volta'}
            </span>
          </div>
          <div className="calendar-header-web-right">
            <span className="description-calendar-header-web-right">
              Escolha até 4 opções
            </span>

            <Icon
              iconName="about-information"
              iconColor="#00688D"
              width={30}
              href="#getsitecontrol?id=373271"
            />
          </div>
        </div>
      )}
      <div className="calendar">
        <div className="div-calendar-month-control">{renderMonthControl()}</div>
        <div className="calendar-week-header">{renderDaysOfWeek()}</div>
        <div className="calendar-body">{renderDaysInMonth()}</div>
      </div>
      {/* <Calendar
        minimumDate={
          getCurrentDate()
        }
        locale={myCustomLocale}
        calendarClassName="responsive-calendar"
        calendarSelectedDayClassName="custom-today-day"
        value={currentDates}
        colorPrimary={isDepartureCalendarState ? '#0fbcf9' : '#036f96'}
        onChange={(dates: []) =>
          maxLimitSelected &&
          dates.length <= maxLimitSelected &&
          setSelectedDays(dates)
        }
        customDaysClassName={currentDepartureDates}
        shouldHighlightWeekends
      /> */}

      <div className="div-footer-button-calendar">
        {/* {currentDates.length > 0 || isMoboile ? ( */}
        {currentDates.length > 0 || true ? (
          <>
            <Button
              className="cancel-button-footer-calendar"
              textClassName="text-title-button-bold"
              title="Limpar"
              onClick={() => onClose && onClose()}
            />
            <Button
              className="button-footer-calendar"
              textClassName="text-title-button-bold-confirm"
              title="OK"
              onClick={() => onConfirm && onConfirm()}
            />
          </>
        ) : (
          <a
            className="text-getsitecontrol-calendar"
            href="#getsitecontrol?id=373271">
            Entenda o calendário multidatas
          </a>
        )}
      </div>
    </div>
  );
}

CalendarPicker.defaultProps = {
  maxLimitSelected: 100,
  departureSelectedDates: [],
  returnSelectedDates: [],
  isVisible: false,
  isMoboile: false,
  currentDatesValues: [],
};

export default CalendarPicker;
