/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import './TripTypeSwitch.css';
import * as IconLibrary from 'react-icons/fi';
import Api from '../../../services/api';
import {useDispatch, useSelector} from 'react-redux';
import {OwnProps} from './types';
import {CalendarDateProps} from '../../DataEntry/CalendarPicker/types';
import {myCustomLocale} from '../../DataEntry/CalendarPicker/const';
import {FiArrowUpRight, FiArrowDownLeft} from 'react-icons/fi';
import {getIsOneWayTrip} from '../../../store/flights';
import Icon from '../../Icon';
import ViewportChecker from '../../../core/helpers/ViewPort';

function TripTypeSwitch({
  title,
  iconName,
  className,
  textClassName,
  departureDates,
  returnDates,
  onClick,
  isOnlyShow,
  isOneWayTripSelected,
  isDisable,
  isDepartureSelected,
  isReturnSelected,
}: OwnProps) {
  const IconComponent = IconLibrary[iconName as keyof typeof IconLibrary];
  const [departureDatesList, setDepartureDatesList] = useState(departureDates);
  const [returnDatesList, setReturnDatesList] = useState(returnDates);

  const [emptyDepartureDatesList, setEmptyDepartureDatesList] = useState<any>(
    [],
  );
  const [emptyReturnDatesList, setEmptyReturnDatesList] = useState<any>([]);

  const isMobile = ViewportChecker();

  useEffect(() => {
    setDepartureDatesList(departureDates);
    setReturnDatesList(returnDates);

    // const arrayInicializado = Array.from({ length: departureDates.length - 4 }, () => null);

    // alert(departureDates.length);
    setEmptyDepartureDatesList(
      Array.from({length: 4 - departureDates.length}, () => null),
    );
    setEmptyReturnDatesList(
      Array.from({length: 4 - returnDates.length}, () => null),
    );
  }, [departureDates, returnDates]);

  const [isOneWay, handlerOneWay] = useState(true);

  const isOneWayTrip = useSelector(getIsOneWayTrip);
  const [isHoveredDepartureInputDate, setIsHoveredDepartureInputDate] =
    useState<boolean>(false);
  const [isHoveredReturnInputDate, setIsHoveredReturnInputDate] =
    useState<boolean>(false);

  useEffect(() => {
    if (isOneWayTripSelected) {
      handlerOneWay(true);
    } else {
      isOneWayTrip === false && handlerOneWay(!!isOneWayTripSelected);
    }
  }, [isOneWayTripSelected]);

  const departureSwitchStyle = !isMobile
    ? {
        backgroundColor:
          isHoveredDepartureInputDate && !isDepartureSelected
            ? '#e0e0e0'
            : isDepartureSelected
            ? '#D2EFFF'
            : '#FFFFFF',
      }
    : {};

  const returnSwitchStyle = !isMobile
    ? {
        backgroundColor: !isDisable
          ? isHoveredReturnInputDate && !isReturnSelected
            ? '#e0e0e0'
            : isReturnSelected
            ? '#D2EFFF'
            : '#FFFFFF'
          : '#DDDDDD',
      }
    : {};

  return (
    <div className="div-trip-type-switch">
      <div
        onMouseEnter={() => !isMobile && setIsHoveredDepartureInputDate(true)}
        onMouseLeave={() => !isMobile && setIsHoveredDepartureInputDate(false)}
        style={departureSwitchStyle}
        className={
          isOnlyShow
            ? 'div-one-way-only-show'
            : isOneWay
            ? 'div-one-way-selected'
            : 'div-one-way-unselected'
        }
        onClick={() => {
          handlerOneWay(true);
          onClick && onClick(true);
        }}>
        <div className={'div-trip-type-switch-content'}>
          <Icon
            iconName="calendar-up-right-arrow"
            width={25}
            iconColor="#036f96"
          />

          {departureDatesList.length > 0 ? (
            <div className="div-trip-type-switch-content-inner">
              {departureDatesList.map((item, index) => (
                <div key={index} className="departure-date-item">
                  <div className="departure-date-item-day-left">
                    <span className={'departure-date-item-day'}>
                      {item.day}
                    </span>
                    <span className={'departure-date-item-month'}>
                      {myCustomLocale.months[item.month]
                        ?.substring(0, 3)
                        .toLowerCase()}
                    </span>
                  </div>
                  <div className="departure-date-item-day-right">
                    <FiArrowUpRight className="departure-date-item-day-right-icon" />
                  </div>
                </div>
              ))}

              {emptyDepartureDatesList.map(() => (
                <div className="div-trip-type-switch-empty-data" />
              ))}
            </div>
          ) : (
            <div className="div-trip-type-switch-content-inner-empty ">
              <span className="text-trip-type-switch-content-inner-empty ">
                Datas de ida
              </span>
            </div>
          )}
        </div>
      </div>
      <div
        onMouseEnter={() => !isMobile && setIsHoveredReturnInputDate(true)}
        onMouseLeave={() => !isMobile && setIsHoveredReturnInputDate(false)}
        style={returnSwitchStyle}
        className={
          isDisable
            ? 'div-road-trip-disable'
            : isOnlyShow
            ? 'div-road-trip-only-show'
            : !isOneWay
            ? 'div-road-trip-selected'
            : 'div-road-trip-unselected'
        }
        onClick={() => {
          handlerOneWay(false);
          onClick && onClick(false);
        }}>
        {/* <div className={'div-trip-type-switch-title'}>
          <span className={'text-trip-type-switch-title'}>Volta</span>
        </div> */}
        <div className={'div-trip-type-switch-content'}>
          <Icon
            iconName="calendar-down-left-arrow"
            width={25}
            iconColor={isDisable ? '#F9F9F9' : '#036f96'}
          />

          {returnDatesList.length > 0 ? (
            <div className="div-trip-type-switch-content-inner">
              {returnDatesList.map((item, index) => (
                <div
                  key={index}
                  className="return-date-item"
                  style={{
                    backgroundColor: `${isOneWayTrip ? '#808080' : '#00688D'}`,
                    borderColor: `${isOneWayTrip ? '#808080' : '#00688D'}`,
                  }}>
                  <div className="departure-date-item-day-left">
                    <span className={'departure-date-item-day'}>
                      {item.day}
                    </span>
                    <span className={'departure-date-item-month'}>
                      {myCustomLocale.months[item.month]
                        ?.substring(0, 3)
                        .toLowerCase()}
                    </span>
                  </div>
                  <div className="departure-date-item-day-right">
                    <FiArrowDownLeft className="departure-date-item-day-right-icon" />
                  </div>
                </div>
              ))}

              {emptyReturnDatesList.map(() => (
                <div className="div-trip-type-switch-empty-data" />
              ))}
            </div>
          ) : (
            <div className="div-trip-type-switch-content-inner-disable">
              <span
                className={
                  isDisable
                    ? 'text-trip-type-switch-content-inner-disable'
                    : 'text-trip-type-switch-content-inner-empty'
                }>
                Datas de volta
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
    // <button className={className} onClick={() => onClick()}>
    //   <>
    //     {iconName && <IconComponent className="icon-button" />}
    //     <span className={textClassName}>{title}</span>
    //   </>
    // </button>
  );
}

TripTypeSwitch.defaultProps = {
  iconName: null,
  className: 'div-button',
  textClassName: 'text-title-button',
  isOnlyShow: false,
  isOneWayTripSelected: false,
  isDisable: false,
};

export default TripTypeSwitch;
